import React, { Fragment, useState, useEffect } from 'react'
import { getData } from '../helpers'
import Mensaje from '../Mensaje'

import Menu from '../Template/Menu'
import Item from './ItemEmpresa'

const Empresas = () =>{
  const [empresas, setEmpresas] = useState([]);
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: true,
    mensaje: 'Cargando...',
    etique: ''
  });

  const getEmpresas =  async () =>{
    const send = await getData('empresas-web');
    if(send.status==="success"){
      setMostrarMensaje(false);
      let empresas = [];
      const empresas_img = send.datos.filter(em=>parseInt(em.status) === 1 && em.logo !==null);
      const empresas_null = send.datos.filter(em=>parseInt(em.status) === 1 && em.logo ===null);
      empresas = empresas_img.concat(empresas_null);
      setEmpresas(empresas);
    }
  }


  useEffect(()=>{
    getEmpresas();
  },[]);

  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={6}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="curved-bg-div wow animated fadeIn" data-wow-delay="0.15s" style={{ height: 50, marginBottom: 100 }}></div>
      <div id="fh5co-features" className="">
        <div className="container">
          <h1 className="second-title"><span className="span-features">EMPRESAS</span></h1>
          <div className="row">
          { mostrarmensaje.mostrar ?
              <div className="col-md-12">
                <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> 
              </div>
             : 
              empresas.length > 0 ?
                empresas.map(item=><Item key={item.empresa_id} item = {item} />)
              : 
                <p>Aún no se han registrado empresas</p>
             }
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default Empresas