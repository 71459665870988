import React from 'react'

const Item = ( { item } ) =>{
  return(
      <div style={{  marginTop: 20, marginBottom: 10}} className="col-sm-3 in-order-2 wow animated fadeInRight text-center" data-wow-delay="0.22s">
        <a href={'empresa?emp='+item.empresa_id}>
          <div className="text-center" style={{ height: 240, borderStyle: 'solid', borderColor: '#d6d6d6', borderWidth: 1, alignContent: 'center', justifyContent: 'center', display: 'flex', alignSelf: 'center', alignItems: 'center'}}>
            {
              item.logo !== null ?
                <img src={item.logo} width="200" loading ="lazy" style= {{ display:'block', margin: 'auto', alignSelf: 'center' }} />
              : 
              <p>Logo no disponible</p>
            }
          </div>
        </a>
        <a href={'empresa?emp='+item.empresa_id}>
          <h4 className="titulo-vacante text-center mt-2w">{item.nombre_comercial}</h4>
        </a>
        <hr/>
      </div>
  )
}

export default Item