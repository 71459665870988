import React, { Fragment, useState, useEffect, useContext } from 'react'
import { getData, deleteItem, insertData } from '../../helpers'

import { AppContext } from '../../contextApp'

import MenuAdministrador from '../../Template/MenuAdministrador'
import Formulario from './Formulario'
import Tabla from './Tabla'

const ContainerUniversidad = () =>{
  const { admin } = useContext(AppContext);
  const [ciudades, setCiudades] = useState([]);
  const [areas, setAreas] = useState([]);
  const [tipovacante, setTipoVacante] = useState([]);
  const [empresas, setEmpresas] = useState([
    {
      value: 0,
      nombre_comercial: 'Cargando empresas...'
    }
  ]);
  const [vacantes, setVacantes] = useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [vacante, setVacante]= useState({
    area_id: 0,
    empresa_id: 0,
    ciudad_id: 0,
    titulo: '',
    jornada: '',
    salario: '',
    desc_corta_vacante: '',
    desc_vacante: '',
    status_vacante: 1,
    fecha_publicacion: '',
    palabras_clave: '',
    discapacitado: 0,
    status: 1
  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getCiudades =  async () =>{
    const send = await getData('ciudades');
    console.log(send)
    if(send.status==="success"){
      setCiudades(send.datos);
    }
  }

  const getAreas =  async () =>{
    const send = await getData('areas');
    console.log(send)
    if(send.status==="success"){
      setAreas(send.datos);
    }
  }

  const getTipoVacante =  async () =>{
    const send = await getData('tipo-vacante');
    console.log(send)
    if(send.status==="success"){
      setTipoVacante(send.datos);
    }
  }

  const getEmpresas =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(parseInt(user.admin) === 1){
      const send = await getData('empresas');
      console.log(send)
      if(send.status==="success"){
        setEmpresas(send.datos);
      }
    }else{
      setVacante({
        ...vacante,
        empresa_id: user.empresa_id
      })
    }
  }


  const getVacantes =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    let empresa_id = 0;
    if(parseInt(user.admin) !==  1){
      empresa_id = user.empresa_id
    }
    let empresa = {
      value: empresa_id
    }
    let ruta = 'filtrar-vacantes-empresa';
    const send = await insertData(ruta,empresa);
    if(send.status==="success"){
      setVacantes(send.datos);
    }
  }

  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data, editor) =>{
    setActualizar(true);
    setVacante(data);
    //setImagen(dominio+'/'+data.img)
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('vacantes/'+data.universidad_id);
    console.log(send);
    if(send.code==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'No se eliminó la vacante',
        etiqueta: 'error'
      })
    }
    if(send.status==="success"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la vacante',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getVacantes();
    }
  }

  useEffect(()=>{
    getCiudades();
    getVacantes();
    getAreas();
    getEmpresas();
    getTipoVacante();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={1} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Publicar</h2>
              <hr/>
            </div>
          </div>
          <Formulario 
            ciudades = {ciudades} 
            areas = {areas}
            empresas = {empresas}
            tipovacante = {tipovacante}
            getVacantes = {getVacantes}

            setActualizar = {setActualizar}
            actualizar = {actualizar}

            setVacante = {setVacante}
            vacante = {vacante}

            admin = {admin}
          />
          <Tabla 
            vacantes = {vacantes}

            actualiza = {actualiza}
            eliminaItem = {eliminaItem}

            mostrarmensaje2 = {mostrarmensaje2}

            botoneliminar = {botoneliminar}
            setBotonEliminar = {setBotonEliminar}
          />
        </div>
    </Fragment>
  )
}

export default ContainerUniversidad