import React, { Fragment, useState, useEffect } from 'react'
import { getData } from '../../helpers'

import MenuAdministrador from '../../Template/MenuAdministrador'
import Formulario from './Formulario'

const ContainerUniversidad = () =>{
  const [ciudades, setCiudades] = useState([]);
  const [universidad, setUniversidad]= useState({
    email: '',
    password: '',
    ciudad_id: 0,
    nombre_comercial: '',
    razon_social: '',
    rfc: '',
    direccion: '',
    logo: '',
    telefono: '',
    nombre_contacto: '',
    telefono_contacto: '',
    status: 1
  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getCiudades =  async () =>{
    const send = await getData('ciudades');
    console.log(send)
    if(send.status==="success"){
      setCiudades(send.datos);
    }
  }

  const getUniversidad =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    const send = await getData('universidades/'+user.universidad_id);
    console.log(send)
    if(send.status==="success"){
      setUniversidad(send.datos);
    }
  }

  useEffect(()=>{
    getCiudades();
    getUniversidad();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={10} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Completar datos de la universidad</h2>
              <hr/>
            </div>
          </div>
          <Formulario 
            ciudades = {ciudades} 
            setUniversidad = {setUniversidad}
            universidad = {universidad}
          />
        </div>
    </Fragment>
  )
}

export default ContainerUniversidad