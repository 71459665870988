import React, { useState, useEffect, Fragment } from 'react';
import { insertData, getData } from './helpers'
import Mensaje from './Mensaje'
import FileBase64 from 'react-file-base64';

const Modal = ( { proyectoid } ) => {
  const [videos, setVideos] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [proyecto, setProyecto] = useState({
    proyecto_id: 0,
    desc_foto_proyecto: '',
    url:'',
    foto_base64:'',
    status: 0
  })
  const [video, setVideo] = useState({
    proyecto_id: 0,
    desc_video: '',
    url:'',
    status: 0
  })

  //Extraer valores
  const {
    url
  } = video;

  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });
  const {message, label} = mensaje;


  const actualizaState = e =>{
    setVideo({
      ...video,
      [e.target.name] : e.target.value
    })
  }

  const getFiles =(file)=>{
    let archivo = (file.base64).split(';');
    console.log(archivo[0]);
    if(archivo[0] === 'data:image/jpeg' || archivo[0] === 'data:image/png'){
      actualizaMensaje({
        status:false,
      })
      setProyecto({
        ...proyecto,
        foto_base64: file.base64
      })
      return;
    }
    actualizaMensaje({
      status:true,
      label: "error",
      message: 'Formato permitido de imagen es: png, jpg, jpeg'
    })
  }

  const getVideos = async (id) =>{
    console.log(id)
    getImagenes(id);
    const send = await getData('videos-de-proyecto/'+proyectoid);
    if(send.status==="success"){
      console.log(send.datos);
      await setVideos(send.datos);
      return;
    }
    setVideos([]);
  }

  const getImagenes = async (id) =>{
    const send = await getData('fotos-proyecto/'+proyectoid);
    if(send.status==="success"){
      await setImagenes(send.datos);
      return;
    }
    setImagenes([]);
  }

  const submitFormulario = async e =>{
    e.preventDefault();
    console.log('Guardando video...');
    console.log(proyectoid);
    if(url === ''){
      actualizaMensaje({
        status:true,
        label: "error",
        message: "Debe agregar una URL válida"
      });
      return;
    }
    let datos_proyecto = {
      proyecto_id: proyectoid,
      desc_video: '',
      url: video.url,
      status: 0
    }
    const send = await insertData('videos-de-proyecto',datos_proyecto);
    console.log(send);
    if(send.status === "success"){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha registrado correctamente."
      });
      setVideo({
        proyecto_id: 0,
        desc_video: '',
        url:'',
        status: 0
      })
      getVideos();
      return;
    }
    actualizaMensaje({
      status:true,
      label: "error",
      message: 'Ha ocurrido un error.'
    })
  }

  const submitFormulario2 = async e =>{
    e.preventDefault();
    console.log('Guardando imagen...');
    if(proyecto.foto_base64 === ''){
      actualizaMensaje({
        status:true,
        label: "error",
        message: "No se ha cargado ninguna imagen"
      });
      return;
    }
    let datos_proyecto = {
      proyecto_id: proyectoid,
      desc_foto_proyecto: '',
      url:'',
      foto_base64: proyecto.foto_base64,
      status: 0
    }
    const send = await insertData('fotos-proyecto',datos_proyecto);
    console.log(send);
    if(send.status === "success"){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha registrado correctamente."
      });
      setProyecto({
        proyecto_id: 0,
        desc_foto_proyecto: '',
        url:'',
        foto_base64:'',
        status: 0
      })
      getImagenes();
      return;
    }
    actualizaMensaje({
      status:true,
      label: "error",
      message: 'Ha ocurrido un error.'
    })
  }

  useEffect(()=>{
    console.log('efecctt')
    getVideos();
  },[proyectoid])


  return (
    <div id="modalMultimediasProyectos" className="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Agregar multimedia</h5>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              {mensaje.status ? <Mensaje etiqueta={label} mensaje={message} />: null}
                <div className="row">
                  <div className="col-md-6">
                    <form
                      onSubmit={submitFormulario}
                    >
                      <div className="form-group">
                        <p>URL de Video</p>
                        <input 
                        type= "text"
                        name="url" 
                        value= {url}
                        onChange={ actualizaState }
                        className="form-control form-control-sm" 
                        required
                        />
                      </div>
                        <button className="mt-1 btn btn-primary">Agregar URL</button>
                    </form>
                    <div>
                      <p style={{ marginTop: 10 }}>Lista de videos</p>
                      {
                        videos ? 
                          videos.map(item=> (
                            <div>
                              <hr/>
                              <a style={{ fontSize: 12 }} href={ item.url }>{ item.url }</a>
                              <hr/>
                            </div>
                          ))
                        : null
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <form
                        onSubmit={submitFormulario2}
                      >
                      <div className="form-group">
                        <img width= "150" className="grid-image" src= {proyecto.icono_logo === '' ? 'img/default_img.png' : proyecto.icono_logo} />
                        <p>Seleccionar Imágenes</p>
                        <FileBase64
                          multiple={ false }
                          onDone={ getFiles.bind(this) } />
                      </div>
                      <button className="mt-1 btn btn-primary">Agregar Imagen</button>
                      <div>
                        <p style={{ marginTop: 10 }}>Lista de imágenes</p>
                        {
                          imagenes ? 
                            imagenes.map(item=> (
                              <div>
                                <hr/>
                                <img width="100" src={ item.foto_base64 } />
                                <hr/>
                              </div>
                            ))
                          : null
                        }
                      </div>
                    </form>
                  </div>
                </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Modal;
