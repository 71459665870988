import React, { Fragment, useState, useEffect, useContext } from 'react'
import { getData, deleteItem, insertData } from '../../helpers'

import { AppContext } from '../../contextApp'

import MenuAdministrador from '../../Template/MenuAdministrador'

import Formulario from './Formulario';
import Tabla from './Tabla'

const ContainerPromociones = () =>{
  const { admin } = useContext(AppContext);
  const [promocion, setPromocion] = useState({
    empresa_id: 0,
    desc_promocion_empresa: '',
    imagen: '',
    status: 0
  })
  const [empresas, setEmpresas] = useState([{
    value: 0,
    nombre_comercial: 'Cargando empresas...'
  }]);
  const [promociones, setPromociones] = useState([]);
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [botoneliminar, setBotonEliminar] = useState(false);


  const getEmpresas =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    console.log(admin)
    if(parseInt(user.admin) ===  1){
      const send = await getData('empresas');
      console.log(send)
      if(send.status==="success"){
        setEmpresas(send.datos);
      }
    }else{
      setPromocion({
        ...promocion,
        empresa_id: user.empresa_id
      })
    }
  }

  const getPromociones =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(parseInt(user.admin) ===  1){
      const send = await getData('promociones-empresa');
      console.log(send)
      if(send.status==="success"){
        setPromociones(send.datos);
        return;
      }
      setPromociones([]);
    }else{
      let datos = {
        empresa_id: user.empresa_id
      }
      const send2 = await insertData('promociones-empresa-empresa',datos);
      if(send2.status==="success"){
        setPromociones(send2.datos);
        return;
      }
      setPromociones([]);
    }
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('promociones-empresa/'+data.promocion_empresa_id);
    console.log(send);
    if(send.code==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'No se eliminó la promocion',
        etiqueta: 'error'
      })
      return;
    }
    if(send.status==="success"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la promocion',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getPromociones();
    }
  }

  useEffect(()=>{
    getEmpresas();
    getPromociones();
  },[]);


  return(
    <Fragment>
      <MenuAdministrador activar={8} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Promociones</h2>
              <hr/>
              <Formulario 
                promocion = {promocion}
                setPromocion = {setPromocion}
                empresas = {empresas}
                admin = {admin}
                getPromociones = {getPromociones}
              />
            </div>
          </div>
          <Tabla 
            promociones = {promociones}
            eliminaItem = {eliminaItem}
            mostrarmensaje2 = {mostrarmensaje2}

            botoneliminar = {botoneliminar}
            setBotonEliminar = {setBotonEliminar}
          />
        </div>
    </Fragment>
  );
}

export default ContainerPromociones