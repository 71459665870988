import React, { Fragment, useState, useEffect, useContext } from 'react'
import { insertData } from '../../helpers'
import Mensaje from '../../Mensaje'

import MenuAdministrador from '../../Template/MenuAdministrador'
import Tabla from './Tabla'

const ContainerEmpresa = () =>{
  const [usuarios, setUsuarios] = useState([]);

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getUsuarios =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    let universidad_id = 0;
    if(user.admin !== 1){
      universidad_id = user.universidad_id
    }
    let universidad = {
      value: universidad_id
    }
    let ruta = 'usuarios-universidad';
    const send = await insertData(ruta,universidad);
    console.log(send)
    if(send.status==="success"){
      setUsuarios(send.datos);
    }
  }

  const validarUsuario = async (status, usuario_id) =>{
    let usuario = {
      status: status,
      usuario_id: usuario_id
    }
    let ruta = 'validar-usuario';
    const send = await insertData(ruta,usuario);
    console.log(send)
    if(send.status==="success"){
      getUsuarios();
      return;
    }

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'No se validó el usuario',
      etiqueta: 'error'
    })

  }


  useEffect(()=>{
    getUsuarios();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={9} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Validar alumnos/egresados</h2>
              <hr/>
            </div>
            <div className="col-md-12">
              { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
            </div>
          </div>
          <Tabla 
            usuarios = {usuarios}
            validarUsuario = {validarUsuario}
          />
        </div>
    </Fragment>
  )
}

export default ContainerEmpresa