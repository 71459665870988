import React, { Fragment, useEffect, useContext, useState } from 'react'
import { AppContext } from '../contextApp'
import ModalActualizaUsuario from '../ModalActualizaUsuario'

const Menu = ({activar}) =>{
  const { admin } = useContext(AppContext);
  const [usuario, setUsuario] = useState({});
  const cerrarSesion= async()=>{
    //document.cookie = "STORAGE_KEY=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem('CANA_USERTOKEN');
    localStorage.removeItem('CANA_USER');
    window.location = '/';
  }

  useEffect( async ()=>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(user){
      setUsuario(user);
    }else{
      window.location = '/'
    }
  },[]);

  return(
    <Fragment>
      <ModalActualizaUsuario />
      <nav className="container navbar navbar-expand-lg2 main-navbar-nav main-navbar-nav2 navbar-light">
        <a className="navbar-brand" href="./"><img src="img/logo_canacintra_color.png" width="250" /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav nav-items-center ml-auto mr-auto">
            {
            !usuario.universidad_id ?
              <li className={activar === 1 ? 'nav-item active' : 'nav-item'} >
                <a className="nav-link " href="/admin-vacantes">Prácticas/Servicio/Vacantes <span className="sr-only">(current)</span></a>
              </li>
            : 
              <Fragment>
                {/* <li className={activar === 7 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link " href="/admin-lista-alumnos">Alumnos/Egresados <span className="sr-only">(current)</span></a>
                </li> */}
                <li className={activar === 8 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link " href="/admin-registrar-fechas">Registrar Fechas <span className="sr-only">(current)</span></a>
                </li>
                <li className={activar === 9 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link " href="/admin-validar-alumnos">Validar Alumnos <span className="sr-only">(current)</span></a>
                </li>
                <li className={activar === 10 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link " href="/admin-datos-universidad">Datos Universidad <span className="sr-only">(current)</span></a>
                </li>
                <li className={activar === 7 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link" href="/admin-convocatorias"  >Convocatorias</a>
                </li>
              </Fragment> 
            }
            {
              admin ?
              <Fragment>
                <li className={activar === 2 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link" href="/admin-empresas" >Empresas</a>
                </li>
                <li className={activar === 3 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link" href="/admin-universidades"  >Universidades</a>
                </li>
                <li className={activar === 7 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link" href="/admin-convocatorias"  >Convocatorias</a>
                </li>
                <li className={activar === 4 ? 'nav-item active' : 'nav-item'} >
                  <a className="nav-link" href="/admin-usuarios"  >Usuarios</a>
                </li>
              </Fragment>
              :
              null
            }
            {
            !usuario.universidad_id ?
            <li className={activar === 5 ? 'nav-item active' : 'nav-item'} >
              <a className="nav-link" href="/admin-consultas"  >Consultas</a>
            </li>
            : null
            }
            {
              usuario.empresa_id || parseInt(usuario.admin) === 1 ?
                <Fragment>
                  {
                  parseInt(usuario.admin) !== 1 ?
                    <li className={activar === 6 ? 'nav-item active' : 'nav-item'} >
                      <a className="nav-link" href="/admin-datos-empresa"  >Datos Empresa</a>
                    </li>
                    : null
                  }
                  <li className={activar === 8 ? 'nav-item active' : 'nav-item'} >
                    <a className="nav-link" href="/admin-promociones"  >Promociones</a>
                  </li>
                </Fragment>
              : null
            }

            <li className="nav-item">
              <button class="btn btn-primary" type="button" onClick={()=>cerrarSesion()} >Cerrar Sesión</button>
            </li>
          </ul>
          <div className="social-icons-header">
            <a href="#"><i className="fas fa-cog" data-toggle="modal" data-target="#modalActualizaUsuario"></i></a>
            
          </div>
        </div>
      </nav>
    </Fragment>    
  );
}

export default Menu