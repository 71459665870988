import React from 'react'

const Item = ( { item, eliminaItemProyecto, ContarInteresados, serValoresID } ) =>{
  return(
    <div className="row" style={{  marginTop: 100, marginBottom: 10}}>
      <div className="col-md-3 animated fadeInLeft text-center">
        {
          item.icono_logo ?
          <img width="200" src={item.icono_logo} />
          :
          <img width="200" src="img/proyecto_img.png" />
        }
      </div>
      <div style={{  marginBottom: 10}} className="col-sm-9 in-order-2 wow animated fadeInRight" data-wow-delay="0.22s">
        <h3 className="titulo-vacante"><a href={'proyecto?id='+item.proyecto_id}>{ item.nombre_proyecto }</a></h3>
        <ContarInteresados proyecto_id = {item.proyecto_id} />
        <h5 className="empresa-vacante">{ item.nombre_proyecto }</h5>
        <p> { item.desc_corta_negocio } </p>
        <span className="circle circle-first fecha-vacante">Fecha: { item.fecha_registro }</span>
        <br/>
        <button type="button" onClick = {()=>serValoresID(item.proyecto_id, true)} className="mt-1 btn btn-warning" data-toggle="modal" data-target="#modalRegistroProyectos">Editar</button>
        <button type="button" onClick = {()=>serValoresID(item.proyecto_id, false)} className="mt-1 btn btn-primary" data-toggle="modal" data-target="#modalMultimediasProyectos" >Agregar multimedia</button>
        <br/>
        <button type="button" onClick={()=>eliminaItemProyecto(item.proyecto_id)} className="mt-1 btn btn-outline-danger btn-sm">Remover</button>
        <hr/>
      </div>
    </div> 
  )
}

export default Item