import React, { Fragment, useState, useEffect } from 'react'
import { getData } from '../helpers'

import Menu from '../Template/Menu'
import Item from './ItemVacante'
import Filtros from './Filtros'

const Vacantes = () =>{
  const [vacantes, setVacantes] = useState([]);
  const [tipovacante, setTipoVacante] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [areas, setAreas] = useState([]);
  const [existe, setExiste] = useState(true);

  const getVacantes =  async () =>{
    const send = await getData('vacantes-web');
    console.log(send)
    if(send.status==="success"){
      setVacantes(send.datos);
    }
  }

  const getTipoVacante =  async () =>{
    const send = await getData('tipo-vacante');
    console.log(send)
    if(send.status==="success"){
      setTipoVacante(send.datos);
    }
  }

  const getCiudades =  async () =>{
    const send = await getData('ciudades');
    console.log(send)
    if(send.status==="success"){
      setCiudades(send.datos);
    }
  }

  const getAreas =  async () =>{
    const send = await getData('areas');
    console.log(send)
    if(send.status==="success"){
      setAreas(send.datos);
    }
  }


  useEffect(()=>{
    getVacantes();
    getCiudades();
    getAreas();
    getTipoVacante();
  },[]);

  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={2}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="wow animated fadeIn" data-wow-delay="0.15s"></div>
      <div id="fh5co-features" className="fh5co-features-outer">
        <div className="container">
        <Filtros 
            areas = {areas}
            ciudades = {ciudades}
            setVacantes = {setVacantes}
            setExiste = {setExiste}
            tipovacante = {tipovacante}
          />
          <h1 className="second-title" style={{ marginTop: 50 }}><span className="span-features">PRÁCTICAS/SERVICIO/VACANTES</span></h1>
          {
            vacantes.length > 0 ?
            vacantes.map(item=><Item key={item.vacante_id} item = {item} path = "vacantes-practicas" />)
            : <p>No se encontraron vacantes</p>
          }
        </div>
      </div>



    </Fragment>
  );
}

export default Vacantes