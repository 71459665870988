import React, { Fragment, useState, useEffect } from 'react'

import Menu from '../Template/Menu'

const Contacto = () =>{
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={4}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="wow animated fadeIn" data-wow-delay="0.15s"></div>
      <div id="fh5co-features" className="fh5co-features-outer">
        <div className="container">
          <h1 className="second-title" style={{ marginTop: 250 }}><span className="span-features">CONTACTANOS</span></h1>
          <p>Para contactarnos envíenos un mensaje.</p>
          <div className = "row">
            <div className= "col-md-6">
                <form>
                <div className="form-group require_nom_comer_emp">
                  <label for="formGroupExampleInput">Nombre*</label>
                  <input 
                    type= "text"
                    className="form-control" 
                    required
                  />
                </div>
                <div className="form-group require_nom_comer_emp">
                  <label for="formGroupExampleInput">Teléfono*</label>
                  <input 
                    type= "text"
                    className="form-control" 
                    required
                  />
                </div>
                <div className="form-group require_nom_comer_emp">
                  <label for="formGroupExampleInput">Correo*</label>
                  <input 
                    type= "text"
                    className="form-control" 
                    required
                  />
                </div>
                <div className="form-group require_nom_comer_emp">
                  <label for="formGroupExampleInput">Comentario*</label>
                  <textarea 
                    className="form-control" 
                    rows= "6"
                  />
                </div>
                <div className="form-group require_nom_comer_emp">
                  <button className="mt-1 btn btn-primary">Enviar</button>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1897.2352663516222!2d-92.940335!3d18.003354!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd439041589ae1f33!2sCanacintra%20Tabasco!5e0!3m2!1ses!2smx!4v1613536773091!5m2!1ses!2smx" width="600" height="450" frameborder="0" style={{ height: 300, width: '95%', marginBottom: 50, marginRight: 100 }}  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>
              <p><i class="fas fa-map-marker-alt"></i> Calle vía 2 # 112, Prolongación Paseo Tabasco y Samarkanda, Col. Tabasco 2000.</p> 
              <p><i class="fas fa-phone"></i> Tel: (993) 316-32-56 Fax: (993) 316-43-65</p> 
              <p><i class="fas fa-envelope"></i> tabasco@canacintra.org.mx</p> 
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default Contacto