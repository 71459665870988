import React from 'react'


const Banner = () =>{
  return(
    <div className="container fh5co-hero-inner">
      <h1 className="animated fadeIn wow" data-wow-delay="0.4s">Bienvenido a <strong><label style={{  paddingLeft: 12, paddingRight: 12, fontWeight:'bold' }}>VINCULA-T</label></strong> con CANACINTRA</h1>
      <p className="animated fadeIn wow" data-wow-delay="0.67s">Una plataforma creada para el beneficio de empresarios, universidades y estudiantes.</p>
      <button className="btn btn-md download-btn-first wow fadeInLeft animated" data-wow-delay="0.85s" data-toggle="modal" data-target="#modalRegistro">Regístrarme</button>
      <button className="btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" data-toggle="modal" data-target="#modalSesion">Iniciar Sesión</button>
      <img className="fh5co-hero-smartphone animated fadeInRight wow" data-wow-delay="1s" src="img/recreiter.png" />
    </div>
  );
}

export default Banner