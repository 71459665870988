import React, { Fragment, useState } from 'react'
import { insertData } from '../helpers'

const Filtros = ( { areas, ciudades, setVacantes, setExiste }) =>{

  const [ filtro, setFiltro] = useState({
    fecha_inicio: '',
    fecha_fin: '',
    salario_inicio: '',
    salario_fin: '',
    ciudad_id: '',
    discapacitado: 1,
    area_id: ''
  });
  const {
    fecha_inicio,
    fecha_fin,
    salario_inicio,
    salario_fin,
    ciudad_id,
    discapacitado,
    area_id,
  } = filtro;

  const actualizaState = e =>{
    setFiltro({
      ...filtro,
      [e.target.name] : e.target.value
    });
  } 

  const submitFormulario = async e =>{
    e.preventDefault();
    console.log('Enviando....')
    setExiste(true);

    let ruta = 'filtrar-vacantes';
    const send = await insertData(ruta,filtro);
    console.log(send)
    if(send.datos){
      setVacantes(send.datos);
    }

    if(send.status ==="not_found"){
      setVacantes([]);
      setExiste(false);
    }
  }

  return(
    <Fragment>
      <form 
        onSubmit = {submitFormulario}
      >
        <div className="row filtros" style= {{ marginTop: 200 }}>
        <div className="col-md-3">
            <label for="formGroupExampleInput2">Municipio:</label>
            <select 
            className="form-control"
            name="ciudad_id" 
            value = {ciudad_id}
            onChange = {actualizaState}
            >
            <option value = "">-- Seleccione un municipio --</option>
            {
              ciudades ?
              ciudades.map(item=>(
                <option 
                  key = {item.ciudad_id}
                  value={item.ciudad_id}
                  >{item.desc_ciudad}
                </option>
              ))
            :null
            }
            </select>
          </div>
          <div className="col-md-3">
            <label for="formGroupExampleInput2">Área:</label>
            <select 
            className="form-control"
            name="area_id" 
            value = {area_id}
            onChange = {actualizaState}
            >
            <option value = "">-- Seleccione un área --</option>
            {
              areas ?
              areas.map(item=>(
                <option 
                  key = {item.area_id}
                  value={item.area_id}
                  >{item.desc_area}
                </option>
              ))
            :null
            }
            </select>
          </div>
            <div className="col-md-2">
              <div className="form-group ">
                <label for="formGroupExampleInput">Fechas:</label>
                  <input 
                    type= "date"
                    name="fecha_inicio" 
                    value= {fecha_inicio}
                    onChange={actualizaState}
                    className="form-control-sm" 
                    />
                  <input 
                    type= "date"
                    name="fecha_fin" 
                    value= {fecha_fin}
                    onChange={actualizaState}
                    className="form-control-sm" 
                    />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group ">
                <label for="formGroupExampleInput">Salario:</label>
                  <input 
                    type= "number"
                    name="salario_inicio" 
                    placeholder= "Mínimo"
                    value= {salario_inicio}
                    onChange={actualizaState}
                    className="form-control-sm" 
                    />
                  <input 
                    type= "number"
                    name="salario_fin" 
                    placeholder= "Máximo"
                    value= {salario_fin}
                    onChange={actualizaState}
                    className="form-control-sm"
                    />
              </div>
            </div>
            <div className="col-md-2 mt-5 text-center">
              <button style={{ backgroundColor: 'white' }} className=" col-md-12 btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" >Buscar</button>
            </div>
          </div>
      </form>
    </Fragment>
  )
}

export default Filtros