import React, { Fragment, useState, useEffect } from 'react'
import { getData } from '../helpers'
import Mensaje from '../Mensaje'

import Menu from '../Template/Menu'
import Item from './ItemUniversidad'

const Empresas = () =>{
  const [universidades, setUniversidades] = useState([]);
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: true,
    mensaje: 'Cargando...',
    etique: ''
  });

  const getUniversidades =  async () =>{
    const send = await getData('universidades-web');
    console.log(send)
    if(send.status==="success"){
      setMostrarMensaje(false);
      let universidades = [];
      const universidadses_img = send.datos.filter(uni=>parseInt(uni.status) === 1 && uni.logo !==null);
      const universidadses_null = send.datos.filter(uni=>parseInt(uni.status) === 1 && uni.logo ===null);
      universidades = universidadses_img.concat(universidadses_null);
      setUniversidades(universidades);
    }
  }


  useEffect(()=>{
    getUniversidades();
  },[]);

  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={5}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="curved-bg-div wow animated fadeIn" data-wow-delay="0.15s" style={{ height: 50, marginBottom: 100 }}></div>
      <div id="fh5co-features" className="">
        <div className="container">
          <h1 className="second-title"><span className="span-features">UNIVERSIDADES</span></h1>
          <div className="row">
            { mostrarmensaje.mostrar ?
              <div className="col-md-12">
                <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> 
              </div>
             : 
              universidades.length > 0 ?
                universidades.map(item=><Item key={item.universidad_id} item = {item} />)
              : 
                <p>Aún no se han registrado universidades</p>
             }
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default Empresas