import React from 'react';

const Modal = ( { postularVancante, postulado} ) => {
  return (
    <div id="modalPostularse" className="modal fade" role="dialog">
      <form>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">¿Está seguro que desea postularse a esta vacante?</h5>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            {
              !postulado ?
                <button type="button" onClick={()=>postularVancante()} className="mt-1 btn btn-primary">¡ Postularme !</button>
              :
                <button type="button" className="mt-1 btn btn-success" data-dismiss="modal">¡ Se ha postulado !</button>
            }
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Modal;
