import React, { Fragment, useState, useEffect, useContext } from 'react'
import { getData, insertData, deleteItem } from '../helpers'
import Mensaje from '../Mensaje'

import Menu from '../Template/Menu'
import Formulario from './Formulario'
import Item from './ItemVacante'
import ItemProyecto from './ItemProyecto'
import ModalRegistoProyectos from '../ModalRegistroProyectos'
import ModalMultimediasProyecto from '../ModalMultimediasProyectos'

import {AppContext} from '../contextApp'

const Perfil = () =>{
  const {proyecto, setProyecto} = useContext(AppContext);
  const [usuario, setUsuario] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [universidades, setUniversidades] = useState([]);
  const [areas, setAreas] = useState([]);
  const [vacantes, setVacantes] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [numinteresados, setNumInteresados] = useState([]);
  const [proyectoid, setProyectoID] = useState(0);
  const [actualizar, setActualizar] = useState(false);
  const [datos, setDatos] = useState({
    nombre: '',
    apellidos: '',
    matricula: '',
    carrera: '',
    egresado:'',
    area_id: 0,
    ciudad_id: 0,
    cv:'',
    status: 0
  });
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });


  const serValoresID = async (id, actualizar) => {
    setProyectoID(id);
    setActualizar(actualizar);
    console.log(actualizar);
    if(actualizar){
      getProyecto(id)
    }else{
      setProyecto({
        usuario_id: 0,
        nombre_proyecto: '',
        desc_corta_negocio:'',
        capital_necesario: 0,
        tipo_alianza_id: 0,
        icono_logo: '',
        representante_proyecto:'',
        num_celular: '',
        correo: '',
        objetivo_general: '',
        integrantes_proyecto: '',
        pagina_web: '',
        facebook: '',
        instagram: '',
        twitter: '',
        youtube: '',
        linkedin: '',
        tiktok: '',
        pinterest: '',
        tiempo_con_negocio: '',
        experiencia_proyecto: '',
        fuentes_ingreso: '',
        ventas_anuales: 0,
        estrategia_clientes: '',
        ubicacion_proyecto: '',
        instalaciones: '',
        status: 0
      });
    }
  }

  const getProyecto = async (id)=>{
    const send = await getData('proyectos/'+id);
    console.log(send)
    if(send.status==="success"){
      setProyecto(send.datos);
      return;
    }
    setProyecto({
      usuario_id: 0,
      nombre_proyecto: '',
      desc_corta_negocio:'',
      capital_necesario: 0,
      tipo_alianza_id: 0,
      icono_logo: '',
      representante_proyecto:'',
      num_celular: '',
      correo: '',
      objetivo_general: '',
      integrantes_proyecto: '',
      pagina_web: '',
      facebook: '',
      instagram: '',
      twitter: '',
      youtube: '',
      linkedin: '',
      tiktok: '',
      pinterest: '',
      tiempo_con_negocio: '',
      experiencia_proyecto: '',
      fuentes_ingreso: '',
      ventas_anuales: 0,
      estrategia_clientes: '',
      ubicacion_proyecto: '',
      instalaciones: '',
      status: 0
    });
  }

  const getPerfil =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(user){
      setUsuario(user);
      const send = await getData('usuarios/'+user.usuario_id);
      console.log(send)
      if(send.status==="success"){
        setDatos(send.datos);
      }else{
        window.location = '/'
      }
    }
  }

  const getCiudades =  async () =>{
    const send = await getData('ciudades');
    console.log(send)
    if(send.status==="success"){
      setCiudades(send.datos);
    }
  }

  const getAreas =  async () =>{
    const send = await getData('areas');
    console.log(send)
    if(send.status==="success"){
      setAreas(send.datos);
    }
  }

  const getUniversidades =  async () =>{
    const send = await getData('universidades');
    console.log(send)
    if(send.status==="success"){
      setUniversidades(send.datos);
    }
  }

  const getVacantes =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    let ruta = 'postulante-usuario';
    let usuario = {
      usuario_id: user.usuario_id
    }
    const send = await insertData(ruta,usuario);
    if(send.status==="success"){
      setVacantes(send.datos);
      return;
    }
    if(send.status==="empty"){
      setVacantes([]);
      return;
    }
  }



  const eliminaItem = async (id) =>{
    const send = await deleteItem('postulantes/'+id);
    if(send.code==="error"){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'No se removió la vacante',
        etiqueta: 'error'
      })
    }
    if(send.status==="success"){
      getVacantes();
    }
  }

  const getInteresados =  async () =>{
    const send = await getData('interesado-proyectos');
    if(send.status==="success"){
      setNumInteresados(send.datos);
    }
  }

  const eliminaItemProyecto = async (id) =>{
    const send = await deleteItem('proyectos/'+id);
    if(send.code==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'No se removió el proyecto',
        etiqueta: 'error'
      })
    }
    if(send.status==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.message,
        etiqueta: 'error'
      })
    }
    if(send.status==="success"){
      getProyectos();
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado el proyecto',
        etiqueta: 'error'
      })
    }
  }

  const getProyectos =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    const send = await getData('proyectos');
    if(send.status==="success"){
      setProyectos(send.datos);
      return;
    }
    setProyectos([]);

  }

  const verFechas = (id) =>{
    window.location = 'fechas-universidades?uni='+id;
  }

  useEffect(()=>{
    getPerfil();
    getAreas();
    getCiudades();
    getVacantes();
    getUniversidades();
    getProyectos();
    getInteresados();
  },[]);

  const ContarInteresados = ( { proyecto_id } ) =>{
    return (
    <div>
      {/* <li className="fa fa-eye"></li> 12  */}
      <li className="fa fa-star"></li> 
      {
        numinteresados.filter( item => item.proyecto_id == proyecto_id ).length
      }
    </div>
    )
  }
  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={3}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="curved-bg-div wow animated fadeIn" data-wow-delay="0.15s" style={{ height: 50, marginBottom: 100 }}></div>
      <ModalRegistoProyectos getProyectos = { getProyectos } actualizar = { actualizar } />
      <ModalMultimediasProyecto proyectoid = { proyectoid } />
      <div id="fh5co-features" className="">
        <div className="container">
          <h1 className="second-title"><span className="span-features">Hola!, <label style={{ fontSize: 20 }}>{usuario.email}</label></span></h1>
          <div className ="row" style={{ marginTop: 80, marginBottom: 100 }}>
            <div className="col-md-6">
              <h5>Completa tus Datos:</h5>
              <p>Completar tus datos correctamente es importante para realizar los trámites.</p>
                <Formulario 
                  ciudades = {ciudades} 
                  areas = {areas}
                  universidades = {universidades}
                  datos = {datos}
                  setDatos = {setDatos}
                />
            </div>
            <div className="col-md-4 offset-md-2">
              {
                datos.universidad_id ? 
                  <button onClick={()=>verFechas(datos.universidad_id)} className="btn btn-primary" style={{ marginBottom: 20 }}>Ver fechas de convocatoria</button>
                : null
              }
              
              <h4 className="second-title"><span className="span-features">Postulaciones</span></h4>
              { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
              {
                vacantes ?
                vacantes.map(item=><Item key={item.vacante_id} item = {item} eliminaItem = {eliminaItem} />)
                : null
              }
            </div>
          </div>
          <div className ="row" style={{ marginTop: 80, marginBottom: 100 }}>
            <div className="col-md-12 text-center">
              <h4 className="second-title"><span className="span-features">Mis proyectos</span></h4>
              <button type="button" onClick = {()=>serValoresID(0, false)} className="mt-1 btn btn-primary" data-toggle="modal" data-target="#modalRegistroProyectos">Nuevo proyecto</button>
              { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
            </div>
            <div className="col-md-12">
            {
              proyectos.length > 0 ?
                proyectos.map(item=><ItemProyecto key={item.proyecto_id} item = {item} eliminaItemProyecto = {eliminaItemProyecto} ContarInteresados = {ContarInteresados} serValoresID = {serValoresID} />)
              : <p>Aún no has registrado proyectos</p>
            }
            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default Perfil