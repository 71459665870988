import React, { Fragment, useState, useEffect } from 'react'
import { insertData, updateItem } from '../helpers'
import FileBase64 from 'react-file-base64';
import Mensaje from '../Mensaje'

const Formulario = ({ ciudades, areas, universidades, datos, setDatos}) =>{

  const { 
    nombre,
    apellidos,
    matricula,
    carrera,
    egresado,
    area_id,
    ciudad_id,
    universidad_id,
    cv
   } = datos;

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  

  const actualizaState = e =>{
    setDatos({
      ...datos,
      [e.target.name] : e.target.value
    });
  } 
  
  const getFiles =(file)=>{
    console.log(file.base64)
    let archivo = (file.base64).split(';');
    console.log(archivo[0]);
    if(archivo[0] === 'data:application/pdf'){
      setMostrarMensaje({
        status:false,
      })
      setDatos({
        ...datos,
        cv: file.base64
      })
      return;
    }

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Formato de archivo no permitido',
      etiqueta: 'error'
    })
  }


  const submitFormulario = async e =>{
    e.preventDefault();
    console.log('Guardando....');
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));

    if (area_id ===0 || ciudad_id ===0 || area_id ==='' || ciudad_id ==='' || !ciudad_id  || !area_id){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Faltan campos(*) obligatorios',
        etiqueta: 'error'
      })
      return;
    }

    let ruta = 'usuarios';
      //Actualizar
      const send = await updateItem(ruta+'/'+user.usuario_id,datos);
      console.log(send);
      if(send.status === "success"){
        setMostrarMensaje({
          mostrar: true,
          etiqueta: 'success',
          mensaje: 'Los datos se han actualizado correctamente'
        });
        return;
      }

      setMostrarMensaje({
        mostrar: true,
        mensaje: 'No se guardó los datos',
        etiqueta: 'error'
      })

    }
    



  return(
    <Fragment>
      <form 
        onSubmit = {submitFormulario}
      >
        <div className="row">
          <div className="col-md-12">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          </div>
          <div className="col-sm-12">
            <form method="POST">
              <div className="form-group require_titulo_vac">
                  <label for="formGroupExampleInput">Matricula</label>
                  <input
                  type= "text"
                  name="matricula" 
                  value= {matricula}
                  onChange={actualizaState}
                  className="form-control" 
                  required
                  />
                </div>
                <div className="form-group require_titulo_vac">
                  <label for="formGroupExampleInput">Nombre</label>
                  <input
                  type= "text"
                  name="nombre" 
                  value= {nombre}
                  onChange={actualizaState}
                  className="form-control" 
                  required
                  />
                </div>
                <div className="form-group require_titulo_vac">
                  <label for="formGroupExampleInput">Apellido</label>
                  <input
                  type= "text"
                  name="apellidos" 
                  value= {apellidos}
                  onChange={actualizaState}
                  className="form-control" 
                  required
                  />
                </div>
                <div className="form-group require_area_vac">
                  <label for="formGroupExampleInput2">Tipo</label>
                  <select 
                  className="form-control"
                  name="egresado" 
                  value = {egresado}
                  onChange = {actualizaState}
                  >
                  <option value = "0">Alumno</option>
                  <option value = "1">Egresado</option>
                  <option value = "2">Maestrante/Doctorante</option>
                  </select>
                </div>
                <div className="form-group require_area_vac">
                  <label for="formGroupExampleInput2">Universidad</label>
                  <select 
                  className="form-control"
                  name="universidad_id" 
                  value = {universidad_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Seleccione la universidad a la que pertenece --</option>
                  {
                    universidades ?
                    universidades.map(item=>(
                      <option 
                        key = {item.universidad_id}
                        value={item.universidad_id}
                        >{item.nombre_comercial}
                      </option>
                    ))
                  :null
                  }
                  </select>
                </div>
                <div className="form-group require_titulo_vac">
                  <label for="formGroupExampleInput">Carrera</label>
                  <input
                  type= "text"
                  name="carrera" 
                  value= {carrera}
                  onChange={actualizaState}
                  className="form-control" 
                  required
                  />
                </div>
                <div className="form-group require_area_vac">
                  <label for="formGroupExampleInput2">Área</label>
                  <select 
                  className="form-control"
                  name="area_id" 
                  value = {area_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Seleccione un área --</option>
                  {
                    areas ?
                    areas.map(item=>(
                      <option 
                        key = {item.area_id}
                        value={item.area_id}
                        >{item.desc_area}
                      </option>
                    ))
                  :null
                  }
                  </select>
                </div>
                <div className="form-group require_area_vac">
                  <label for="formGroupExampleInput2">Municipio</label>
                  <select 
                  className="form-control"
                  name="ciudad_id" 
                  value = {ciudad_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Seleccione un municipio --</option>
                  {
                    ciudades ?
                    ciudades.map(item=>(
                      <option 
                        key = {item.ciudad_id}
                        value={item.ciudad_id}
                        >{item.desc_ciudad}
                      </option>
                    ))
                  :null
                  }
                  </select>
                </div>
                <div className="form-group">
                  <label >Curriculum (pdf) Máximo 2M</label>
                  <br />
                  <FileBase64
                    multiple={ false }
                    onDone={ getFiles.bind(this) } />
                  
                </div> 
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button className="mt-1 btn btn-warning">Actualizar datos</button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default Formulario