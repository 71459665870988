import React, { useState } from 'react'
import MaterialTable from 'material-table';
import Mensaje from '../../Mensaje'

const Tabla = ({ convocatorias, mostrarmensaje2, eliminaItem, botoneliminar, setBotonEliminar }) =>{

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }


  const [columnas, setColumnas] = useState(
    [
      { title: "Descripción", field: "desc_convocatoria_universidad" },
      { title: "Imagen", 
        field: "nombre_comercial",
        render: (row)=><div><img width={100} src={row.imagen}  /></div>
      },
      {
        title: "",
        render: (row)=><div>
        <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#mediumModalEliminaCotizacion">
          <li className="fa fa-trash"></li>
        </button>
        {/* {
          botoneliminar ?
            <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm"  >
              Confirmar
            </button>
          : null
        } */}
        </div>,
      }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="offset-md-10 col-md-2 text-right">
      </div>
      <div className="col-md-12">
        { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Lista de convocatorias"
      columns={columnas}
      data={convocatorias}
      onRowSelected
  />
  </div>
  )
}

export default Tabla