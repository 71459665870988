import React, { Fragment, useState, useEffect } from 'react'
import { getData, deleteItem } from '../../helpers'

import MenuAdministrador from '../../Template/MenuAdministrador'
import Formulario from './Formulario'
import Tabla from './Tabla'

const ContainerEmpresa = () =>{
  const [ciudades, setCiudades] = useState([]);
  const [giros, setGiros] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [empresa, setEmpresa]= useState({
    email: '',
    password: '',
    ciudad_id: 0,
    giro_id: 0,
    nombre_comercial: '',
    razon_social: '',
    rfc: '',
    direccion: '',
    logo: '',
    telefono: '',
    nombre_contacto: '',
    telefono_contacto: '',
    status: 1
  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getCiudades =  async () =>{
    const send = await getData('ciudades');
    console.log(send)
    if(send.status==="success"){
      setCiudades(send.datos);
    }
  }

  const getGiros =  async () =>{
    const send = await getData('giros');
    console.log(send)
    if(send.status==="success"){
      setGiros(send.datos);
    }
  }

  const getEmpresas =  async () =>{
    const send = await getData('empresas');
    console.log(send)
    if(send.status==="success"){
      setEmpresas(send.datos);
    }
  }

  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setEmpresa(data);
    //setImagen(dominio+'/'+data.img)
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('empresas/'+data.empresa_id);
    console.log(send);
    if(send.code==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'No se eliminó la empresa',
        etiqueta: 'error'
      })
    }
    if(send.status==="success"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la empresa',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getEmpresas();
    }
  }


  useEffect(()=>{
    getCiudades();
    getGiros();
    getEmpresas();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={2} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Alta Empresas</h2>
              <hr/>
            </div>
          </div>
          <Formulario 
            ciudades = {ciudades} 
            giros = {giros}
            getEmpresas = {getEmpresas}

            setActualizar = {setActualizar}
            actualizar = {actualizar}

            setEmpresa = {setEmpresa}
            empresa = {empresa}
          />
          <Tabla 
            empresas = {empresas}

            actualiza = {actualiza}
            eliminaItem = {eliminaItem}

            mostrarmensaje2 = {mostrarmensaje2}

            botoneliminar = {botoneliminar}
            setBotonEliminar = {setBotonEliminar}

          />
        </div>
    </Fragment>
  )
}

export default ContainerEmpresa