import React, { Fragment, useState, useEffect, useContext } from 'react'
import { getData, insertData } from '../../helpers'

import { AppContext } from '../../contextApp'

import MenuAdministrador from '../../Template/MenuAdministrador'
import Formulario from './Formulario'
import Tabla from './Tabla'

const ContainerEmpresa = () =>{
  const { admin } = useContext(AppContext);
  const [vacantes, setVacantes] = useState([]);
  const [empresas, setEmpresas] = useState([
    {
      value: 0,
      label: 'Cargando empresas...'
    }
  ]);

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });


  const getEmpresas =  async () =>{
    const send = await getData('empresas');
    console.log(send)
    if(send.status==="success"){
      let list_empresas = [];
      send.datos.map(item=>{
        list_empresas.push({
          value: item.empresa_id,
          label: item.nombre_comercial
        })
      })
      setEmpresas(list_empresas);
    }
  }

  const getVacantes =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    let empresa_id = 0;
    if(user.admin !== 1){
      empresa_id = user.empresa_id
    }
    let empresa = {
      value: empresa_id
    }
    let ruta = 'filtrar-vacantes-empresa';
    const send = await insertData(ruta,empresa);
    if(send.status==="success"){
      setVacantes(send.datos);
    }
  }

  useEffect(()=>{
    getEmpresas();
    getVacantes();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={5} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Consultas</h2>
              <hr/>
            </div>
          </div>
          {
            admin ? 
              <Formulario 
              empresas = {empresas}
              vacantes = {vacantes}
              setVacantes = {setVacantes}
              />
            :
            null
          }
          <Tabla 
            vacantes = {vacantes}
          />
        </div>
    </Fragment>
  )
}

export default ContainerEmpresa