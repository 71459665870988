import React, { Fragment, useState, useEffect } from 'react'
import { getData, insertData } from '../helpers'
import { useLocation } from 'react-router-dom'

import Menu from '../Template/Menu'
import Mensaje from '../Mensaje'
import { findRenderedDOMComponentWithClass } from 'react-dom/cjs/react-dom-test-utils.development'

const Universidades = () =>{
  const [imagenes, setImagenes] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [universidad, setUniversidad]= useState({});
  const [convocatorias, setConvocatorias] = useState([]);
  const [statusregistro, setStatusRegistro] = useState(false);
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: true,
    mensaje: 'Cargando...',
    etique: ''
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const getImagenes =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(user){
      let id = query.get("uni");
      if(!id || id ==='null'){
        window.location = '/universidades';
      }
      const send = await getData('imagen-fecha/'+id);
      console.log(send)
      if(send.status==="success"){
        setImagenes(send.datos);
        setMostrarMensaje(false);
        return;
      }
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Debe ser un usuario registrado',
      etiqueta: 'error'
    })
    setStatusRegistro(true);
  }

  const getFechas =  async () =>{
    let id = query.get("uni");
    const send = await getData('registrar-fecha/'+id);
    console.log(send)
    if(send.status==="success"){
      setFechas(send.datos);
      setMostrarMensaje(false);
    }
  }

  const getUniversidad =  async () =>{
    let id = query.get("uni");
    const send = await getData('universidades/'+id);
    console.log(send)
    if(send.status==="success"){
      setUniversidad(send.datos);
    }
  }

  const getConvocatorias =  async () =>{
    let id = query.get("uni");
    let datos = {
      universidad_id: id
    }
    const send = await insertData('convocatorias-universidad-universidad',datos);
    console.log(send)
    if(send.status==="success"){
      setConvocatorias(send.datos);
    }
  }

  useEffect(()=>{
    getImagenes();
    getUniversidad();
    getFechas();
    getConvocatorias();
  },[]);

  const FechaPeriodo = ({ item }) =>(
    <Fragment>
      <div className= "row">
        <div className="col-md-4">
            <h5 style={{ textTransform: 'uppercase' }}>{item.tipo}</h5>
        </div>
        <div className="col-md-4">
            <h5>{item.fecha_inicio}</h5>
        </div>
        <div className="col-md-4">
            <h5>{item.fecha_fin}</h5>
        </div>
      </div>
      <hr/>
    </Fragment>
  )
  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={5}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="curved-bg-div wow animated fadeIn" data-wow-delay="0.15s" style={{ height: 50, marginBottom: 100 }}></div>
      <div id="fh5co-features" className="">
        <div className="container text-center">
          <h1 className="second-title"><span className="span-features">{ universidad.nombre_comercial }</span></h1>
          { 
            imagenes.length > 0 || fechas.length > 0 ?
            <Fragment>
              <div className="col-md-12" style={{ marginTop: 30, marginBottom: 50 }}>
                <h3>PERIODOS</h3>
              </div>
              {
                fechas.map(item=>(
                  <FechaPeriodo item = {item} />
                ))
              }
              {
                imagenes.map(item=>(
                  <div style = {{ margin: 50 }}>
                    <img width = "600" src = {item.img} />
                  </div>
                ))
              }
              </Fragment>
            :
            null
          }
        </div>
        <div className="container text-center">
          <div className="col-md-12" style={{ marginTop: 100, marginBottom: 50 }}>
            {
              convocatorias.length > 0 ?
                <h3><b>CONVOCATORIAS</b></h3>
              : null
            }
          </div>
          {
            convocatorias.length > 0 ?
              convocatorias.map(item=>(
                <div className='text-center' style={{ marginTop: 50 }}>
                  <hr/>
                  <p>{item.desc_convocatoria_universidad}</p>
                  <img width="80%" src={item.imagen}  />
                </div>
              ))
            : null
          }
        </div>
        <div style= {{ marginTop: 50, marginBottom: 50 }} className="text-center">
          <button  className="btn btn-md btn-primary wow fadeInLeft animated" data-wow-delay="0.85s" data-toggle="modal" data-target="#modalRegistro">Regístrarme</button>
        </div>
      </div>



    </Fragment>
  );
}

export default Universidades