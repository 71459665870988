import React, { Fragment, useState, useEffect, useContext } from 'react'
import { getData, deleteItem, insertData } from '../../helpers'

import { AppContext } from '../../contextApp'

import MenuAdministrador from '../../Template/MenuAdministrador'

import Formulario from './Formulario';
import Tabla from './Tabla'

const ContainerConvocatoria = () =>{
  const { admin } = useContext(AppContext);
  const [convocatoria, setConvocatoria] = useState({
    empresa_id: 0,
    desc_promocion_empresa: '',
    imagen: '',
    status: 0
  })
  const [empresas, setEmpresas] = useState([{
    value: 0,
    nombre_comercial: 'Cargando empresas...'
  }]);
  const [convocatorias, setConvocatorias] = useState([]);
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [botoneliminar, setBotonEliminar] = useState(false);


  const getUniversidades =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    console.log(admin)
    if(parseInt(user.admin) ===  1){
      const send = await getData('universidades');
      console.log(send)
      if(send.status==="success"){
        setEmpresas(send.datos);
      }
    }else{
      setConvocatoria({
        ...convocatoria,
        universidad_id: user.universidad_id
      })
    }
  }

  const getConvocatorias =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    console.log(user.admin)
    if(parseInt(user.admin) ===  1){
      const send = await getData('convocatorias-universidad');
      console.log(send)
      if(send.status==="success"){
        setConvocatorias(send.datos);
        return;
      }
      setConvocatorias([]);
    }else{
      let datos = {
        universidad_id: user.universidad_id
      }
      const send2 = await insertData('convocatorias-universidad-universidad',datos);
      if(send2.status==="success"){
        setConvocatorias(send2.datos);
        return;
      }
      setConvocatorias([]);
    }
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('convocatorias-universidad/'+data.convocatoria_universidad_id);
    console.log(send);
    if(send.code==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'No se eliminó la convocatoria',
        etiqueta: 'error'
      })
      return;
    }
    if(send.status==="success"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la convocatoria',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getConvocatorias();
    }
  }

  useEffect(()=>{
    getUniversidades();
    getConvocatorias();
  },[]);


  return(
    <Fragment>
      <MenuAdministrador activar={7} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Convocatorias</h2>
              <hr/>
              <Formulario 
                convocatoria = {convocatoria}
                setConvocatoria = {setConvocatoria}
                empresas = {empresas}
                admin = {admin}
                getConvocatorias = {getConvocatorias}
              />
            </div>
          </div>
          <Tabla 
            convocatorias = {convocatorias}
            eliminaItem = {eliminaItem}
            mostrarmensaje2 = {mostrarmensaje2}

            botoneliminar = {botoneliminar}
            setBotonEliminar = {setBotonEliminar}
          />
        </div>
    </Fragment>
  );
}

export default ContainerConvocatoria