import React, { Fragment, useState } from 'react'
import { insertData, updateItem } from '../../helpers'
import FileBase64 from 'react-file-base64';
import Mensaje from '../../Mensaje'

const Formulario = ({ ciudades, giros, getEmpresas, empresa, setEmpresa, actualizar, setActualizar }) =>{

  const { email, password, ciudad_id, giro_id, nombre_comercial, razon_social, rfc, direccion, telefono, nombre_contacto, telefono_contacto, logo, status } = empresa;

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  
  const actualizaState = e =>{
    setEmpresa({
      ...empresa,
      [e.target.name] : e.target.value
    });
  } 

  const getFiles =(file)=>{
    console.log(file.base64)
    setEmpresa({
      ...empresa,
      logo: file.base64
    })
  }

  const reiniciarForm = () =>{
    //Reiniciar form
    setEmpresa({
      ...empresa,
      email: '',
      password: '',
      nombre_comercial: '',
      razon_social: '',
      rfc: '',
      direccion: '',
      logo: '',
      telefono: '',
      nombre_contacto: '',
      telefono_contacto: '',
      status: 1
    })
    //Actualizar tabla
    getEmpresas();
  }
  
  const submitFormulario = async e =>{
    e.preventDefault();

    let ruta = 'empresas';
    if(!actualizar){
      //Validar
      if (email ==='' || password==='' || giro_id ===0 || ciudad_id ===0 || nombre_comercial==='' ){
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Faltan campos(*) obligatorios',
          etiqueta: 'error'
        })
        return;
      }
      //Guardar
      const send = await insertData(ruta,empresa);
      console.log(send);
      if(send.status === "success"){
        setMostrarMensaje({
          mostrar: true,
          etiqueta: 'success',
          mensaje: 'La empresa se ha guardado correctamente'
        });
        reiniciarForm();
        return;
      }
    }else{
      //Validar
      if (giro_id ===0 || ciudad_id ===0 || nombre_comercial==='' ){
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Faltan campos(*) obligatorios',
          etiqueta: 'error'
        })
        return;
      }
      //Actualizar
      const send = await updateItem(ruta+'/'+empresa.empresa_id,empresa);
      console.log(send);
      if(send.status === "success"){
        setMostrarMensaje({
          mostrar: true,
          etiqueta: 'success',
          mensaje: 'La empresa se ha actualizado correctamente'
        });

        reiniciarForm();
        return;
      }
    }


    setMostrarMensaje({
      mostrar: true,
      mensaje: 'No se guardó la empresa',
      etiqueta: 'error'
    })
  
  }

  return(
    <Fragment>
      <form 
        onSubmit = {submitFormulario}
      >
        <div className="row">
          <div className="col-md-12">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          </div>
          <div className="col-md-6">
            <div className="form-group require_email_emp">
              <label for="formGroupExampleInput">Email*</label>
                <input 
                  type= "email"
                  name="email" 
                  value= {email}
                  onChange={actualizaState}
                  className="form-control" 
                  required = {!actualizar ? 'requerid' : ''}
                  />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group require_contra_emp">
              <label for="formGroupExampleInput">Contraseña*</label>
                <input 
                  type= "password"
                  name="password" 
                  value= {password}
                  onChange={actualizaState}
                  className="form-control" 
                  required = {!actualizar ? 'requerid' : ''}
                  />
            </div>
          </div>
          <hr/>
          <div className="col-sm-6">
                <div className="form-group require_nom_comer_emp">
                  <label for="formGroupExampleInput">Nombre Comercial*</label>
                  <input 
                    type= "text"
                    name="nombre_comercial" 
                    value= {nombre_comercial}
                    onChange={actualizaState}
                    className="form-control" 
                    required
                  />
                </div>
                <div className="form-group require_rfc_emp">
                  <label for="formGroupExampleInput">RFC*</label>
                  <input 
                    type= "text"
                    name="rfc" 
                    value= {rfc}
                    onChange={actualizaState}
                    className="form-control" 
                    required
                  />
                </div>
                <div className="form-group require_ciudad_emp">
                  <label for="formGroupExampleInput2">Ciudad*</label>
                  <select 
                  className="form-control"
                  name="ciudad_id" 
                  value = {ciudad_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Seleccione un municipio --</option>
                  {
                    ciudades ?
                    ciudades.map(item=>(
                      <option 
                        key = {item.ciudad_id}
                        value={item.ciudad_id}
                        >{item.desc_ciudad}
                      </option>
                    ))
                  :null
                  }
                  </select>
                </div>
                <div className="form-group require_tel_emp">
                  <label for="formGroupExampleInput">Teléfono*</label>
                  <input 
                    type= "text"
                    name="telefono" 
                    value= {telefono}
                    onChange={actualizaState}
                    className="form-control" 
                    required
                  />
                </div>
          </div>
          <div className="col-sm-6">
              <div className="form-group require_razon_emp">
                <label for="formGroupExampleInput">Razón Social*</label>
                <input 
                    type= "text"
                    name="razon_social" 
                    value= {razon_social}
                    onChange={actualizaState}
                    className="form-control" 
                    required
                  />
              </div>
              <div className="form-group require_direccion_emp">
                <label for="formGroupExampleInput">Dirección</label>
                <input 
                    type= "text"
                    name="direccion" 
                    value= {direccion}
                    onChange={actualizaState}
                    className="form-control" 
                  />
              </div>
              <div className="form-group require_giro_emp">
                <label for="formGroupExampleInput2">Sector*</label>
                <select 
                  className="form-control"
                  name="giro_id" 
                  value = {giro_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Seleccione un giro --</option>
                  {
                    giros ?
                    giros.map(item=>(
                      <option 
                        key = {item.giro_id}
                        value={item.giro_id}
                        >{item.desc_giro}
                      </option>
                    ))
                  :null
                  }
                  </select>
              </div>
              <div className="form-group">
              <label >Logo</label>
                <br/>
                <img src={logo} width="200" />
                <br />
                <FileBase64
                  multiple={ false }
                  onDone={ getFiles.bind(this) } />
              </div> 
              <div className="form-group">
                  <select
                    className = "form-control"
                    name="status"
                    value = {status ? status : 0}
                    onChange = {actualizaState}
                  >
                    <option value="0">Inactivo</option>
                    <option value="1">Activo</option>
                  </select>
              </div> 
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group require_nom_contac_emp">
                <label for="formGroupExampleInput">Nombre de Contacto</label>
                <input 
                    type= "text"
                    name="nombre_contacto" 
                    value= {nombre_contacto}
                    onChange={actualizaState}
                    className="form-control" 
                  />
              </div>
          </div>
          <div className="col-md-6">
              <div className="form-group require_tel_emp_contact">
                <label for="formGroupExampleInput">Teléfono Contacto</label>
                <input 
                    type= "text"
                    name="telefono_contacto" 
                    value= {telefono_contacto}
                    onChange={actualizaState}
                    className="form-control" 
                  />
              </div>
          </div>
          
        </div>
        <div className="row">
          <div className="col-md-12">
            {
            actualizar ?
            <button className="mt-1 btn btn-warning">Actualizar empresa</button>
            :
            <button className="mt-1 btn btn-primary">Guardar empresa</button>
            }
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default Formulario