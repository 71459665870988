import Main from './components/Main'
import AppProvider from './components/contextApp'

function App() {
  return (
    <AppProvider>
      <Main />
    </AppProvider>
  );
}

export default App;
