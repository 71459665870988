import React, { Fragment, useState } from 'react'
import { insertData } from '../../helpers'
import Select from 'react-select';
import Mensaje from '../../Mensaje'

const Formulario = ({ empresas, vacantes, setVacantes}) =>{
  const [empresa, setEmpresa] = useState({
    value: 0
  });
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });


  const setEmpresaID = (empresa) =>{
    console.log(empresa);
    setEmpresa({
      value: empresa.value
    });
  }


  const submitFormulario = async e =>{
    e.preventDefault();
    console.log('Consultando....')
    let ruta = 'filtrar-vacantes-empresa';
    const send = await insertData(ruta,empresa);
    if(send.status==="success"){
      setVacantes(send.datos);
    }

  }

  return(
    <Fragment>
      <form 
        onSubmit = {submitFormulario}
      >
        <div className="row">
          <div className="col-md-12">
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Selecciona un empresa:</label>
              <Select 
                options={empresas} 
                onChange={setEmpresaID}
              />
            </div> 
          </div>
          <div className="col-md-4">
            <button className="mt-1 btn btn-primary">Consultar</button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default Formulario