import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Inicio from './Inicio/Inicio'
import ContainerVacantesPracticas from './VacantesPracticas_/ContainerVacantes'
import ContainerVacantesServicio from './VacantesServicio/ContainerVacantes'
import ContainerProyectos from './Proyectos/ContainerProyectos'
import Detalleproyecto from './Proyectos/DetalleProyecto'
import ContainerEmpresas from './Empresas/ContainerEmpresas'
import DetalleEmpresa from './Empresas/DetalleEmpresa'
import ContainerUniversidades from './Universidades/ContainerUniversidades'
import ContainerUniversidadesFechas from './Universidades/ContainerUniversidadesFechas'
import ContainerPerfil from './Perfil/ContainerPerfil'
import DetalleVacante from './VacantesPracticas_/DetalleVacante'
import ContainerContacto from './Contacto/ContainerContacto'
import ContainerAvisoPrivacidad from './AvisoPrivacidad/ContainerAvisoPrivacidad'
import Footer from './Template/Footer'

import ModalRegistro from './ModalRegistro'
import ModalSesion from './ModalSesion'

const Main = () => {
  return (
    <Router>
      <main>
        <div id="page-wrap">
          <ModalRegistro />
          <ModalSesion />
          <Switch>
            <Route path="/admin-usuario"  component={ContainerPerfil} />
            <Route path="/empresas"  component={ContainerEmpresas} />
            <Route path="/empresa"  component={DetalleEmpresa} />
            <Route path="/universidades"  component={ContainerUniversidades} />
            <Route path="/fechas-universidades"  component={ContainerUniversidadesFechas} />
            <Route path="/vacante"  component={DetalleVacante} />
            <Route path="/vacantes-practicas"  component={ContainerVacantesPracticas} />
            <Route path="/vacantes-servicio"  component={ContainerVacantesServicio} />
            <Route path="/proyecto"  component={Detalleproyecto} />
            <Route path="/proyectos"  component={ContainerProyectos} />
            <Route path="/contacto"  component={ContainerContacto} />
            <Route path="/aviso-privacidad"  component={ContainerAvisoPrivacidad} />
            <Route path="/"  component={Inicio} />
            
          </Switch>
        </div>
      </main>
      <Footer/>
    </Router>

  );
}

export default Main;
