//const baseRuta = 'http://127.0.0.1:8000/api/'
const baseRuta = 'https://vincula-t.golsystems.com/api/'
const token = localStorage.getItem('CANA_USERTOKEN');

export async function login(ruta,objeto){
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(objeto),

    };
    let response = await fetch(baseRuta+ruta, requestOptions);
    let data = await response.json();
    return data;
  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}

export async function logout(){
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json' 
      }
    };
    let response = await fetch(baseRuta+'logout', requestOptions);
    let data = await response.json();
    return data;
  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}

export async function insertData(ruta,objeto){
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(objeto),

    };
    let response = await fetch(baseRuta+ruta, requestOptions);
    let data = await response.json();
    return data;
  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}

export async function getData(ruta){
  try{
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json' 
      }
    };

    let response = await fetch(baseRuta+ruta,requestOptions);
    let data = await response.json();
    return data;

  }catch(err){
    return {status:'error', message:'Ha ocurrido algo:' +err};
  }
}


export async function updateItem(ruta,objeto){
  try{
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(objeto),
    };

    let response = await fetch(baseRuta+ruta,requestOptions);
    let data = await response.json();
    return data;

  }catch(err){
    return {status:'danger', message:'Ha ocurrido algo:' +err};
  }
}

export async function deleteItem(ruta){
  try{
    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json' 
      }
    };

    let response = await fetch(baseRuta+ruta,requestOptions);
    let data = await response.json();
    return data;

  }catch(err){
    return {status:'danger', message:'Ha ocurrido algo:' +err};
  }
}
