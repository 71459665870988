import React, { Fragment, useState } from 'react'
import { insertData } from '../../helpers'
import FileBase64 from 'react-file-base64';
import Mensaje from '../../Mensaje'

const Formulario = ({ imagen, setImagen, getImagenes }) =>{
  const { img } = imagen;
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  

  const getFiles =(file)=>{
    let archivo = (file.base64).split(';');
    console.log(archivo[0]);
    if(archivo[0] === 'data:image/jpeg' || archivo[0] === 'data:image/png'){
      setMostrarMensaje({
        status:false,
      })
      setImagen({
        ...imagen,
        img: file.base64
      })
      return;
    }
    setMostrarMensaje({
      status:true,
      label: "error",
      message: 'Formato permitido de imagen es: png, jpg, jpeg'
    })
  }


  const submitFormulario = async e =>{
    e.preventDefault();
    //Validar
    if (img ===''){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe seleccionar una imagen',
        etiqueta: 'error'
      })
      return;
    }

    //Guardar
    let ruta = 'imagen-fecha';
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    let datos_imagen = {
      img: imagen.img,
      status: imagen.status,
      universidad_id: user.universidad_id
    }
    const send = await insertData(ruta,datos_imagen);
    console.log(send);
    if(send.status === "success"){
      setMostrarMensaje({
        mostrar: true,
        etiqueta: 'success',
        mensaje: 'Se ha subido la imagen correctamente'
      });
      getImagenes();
      return;
    }

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'No se guardó la imagen, revise que pese menos de 2M',
      etiqueta: 'error'
    })
  
  }

  return(
    <Fragment>
      <form 
        onSubmit = {submitFormulario}
      >
        <div className="row">
          <div className="col-md-12">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          </div>
          <div className="col-sm-3">
                <div className="form-group require_nom_comer_emp">
                  <label for="formGroupExampleInput">Seleccione una imagen*</label>
                  <FileBase64
                  multiple={ false }
                  onDone={ getFiles.bind(this) } />
                </div>
          </div>
          <div className="col-md-3">
            <button className="mt-4 btn btn-warning">Subir imagen</button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default Formulario