import React, { Fragment } from 'react'

import Menu from '../Template/Menu'
import Banner from '../Template/Banner'

const Inicio = () =>{
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper">
        <Menu activar={1} />
        <Banner />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}

      <div className="fh5co-advantages-outer">
        <div className="container">
          <h1 className="second-title"><span className="span-perfect">¿Que</span> <span className="span-features">es?</span></h1>
          <small>VINCULA-T</small>

          <div className="row fh5co-advantages-grid-columns wow animated fadeIn mt-50" data-wow-delay="0.36s">

            <div className="col-sm-4">
              <img className="grid-image" src="img/idea.png" />
              <h1 className="grid-title">Conoce</h1>
              {/* <p className="grid-desc">Bolsa de Trabajo CANACINTRA Tabasco, es una plataforma creada para el beneficio de empresarios, universidades y estudiantes.</p> */}
            </div>

            <div className="col-sm-4">
              <img className="grid-image" src="img/personas.png" />
              <h1 className="grid-title">Servicios</h1>
              {/* <p className="grid-desc">
                  -	Servicio gratuitito para afiliados CANACINTRA
                  -	Acceso a curriculums  certificados
                  -	Registro ilimitado de Vacantes
              </p> */}
            </div>

            <div className="col-sm-4">
              <img className="grid-image" src="img/people.png" />
              <h1 className="grid-title">Beneficios</h1>
              {/* <p className="grid-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem cupiditate.</p> */}
            </div>


          </div>
        </div>
      </div>

      {/* <!-- ==========================================================================================================
                                FEATURES
        ========================================================================================================== --> */}

      <div id="fh5co-features" className="fh5co-features-outer">
        <div className="container">

          <div className="row fh5co-features-grid-columns" style = {{ marginTop: 100 }}>

            <div className="col-sm-6 in-order-1 wow animated fadeInLeft" data-wow-delay="0.22s">
              <div className="col-sm-image-container">
                <img className="img-float-left" src="img/estudiante.png" />
              </div>
            </div>

            <div className="col-sm-6 in-order-2 sm-6-content wow animated fadeInRight" data-wow-delay="0.22s">
              <h1>CONOCE</h1>
              <p>VINCULA-T con CANACINTRA Tabasco, es una plataforma creada para el beneficio de empresarios, universidades y estudiantes. 
                A través de nuestro portal podrás optimizar el proceso de reclutamiento y selección del personal para tu empresa. 
                También podrás acceder a la información de alumnos para realizar prácticas profesionales en tu empresa de las universidades vinculadas a CANACINTRA Tabasco.
                </p>
                <br/>

              <h1>SERVICIOS</h1>
              <li>Reclutamiento</li>
              <li>Selección de Personal</li>
              <li>Vinculación de alumnos para prácticas profesionales</li>
              <li>Renta de salas / Oficinas Virtuales</li>
              <li>Exámenes (Psicométricos, Socioeconómicos, etc.) </li>
              <li>Promoción de Vacantes en Redes Sociales</li>
              <br/>

              <span className="circle circle-first"><i className="fab fa-instagram"></i></span>
              <span className="circle circle-middle"><i className="fab fa-facebook"></i></span>
              <span className="circle circle-last"><i className="fab fa-twitter"></i></span>
            </div>
          </div> 


        </div>
      </div>


      {/* <!-- ==========================================================================================================
                                                    BOTTOM
        ========================================================================================================== --> */}

      <div id="fh5co-download" className="fh5co-bottom-outer">
        <div className="overlay">
          <div className="container fh5co-bottom-inner">
            <div className="row">
              <div className="col-sm-6">
                <h1>BENEFICIOS</h1>
                <li style={{ color: 'white' }}> Servicio gratuitito para afiliados CANACINTRA</li>
                <li style={{ color: 'white' }}> Acceso a curriculums  certificados</li>
                <li style={{ color: 'white' }}> Registro ilimitado de Vacantes</li>
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <button style={{ backgroundColor: 'red', color: 'white' }} className="btn btn-md download-btn-first wow fadeInLeft animated" data-wow-delay="0.85s" data-toggle="modal" data-target="#modalRegistro">Regístrarme</button>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default Inicio