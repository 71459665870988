import React, { useState } from 'react'
import MaterialTable from 'material-table';


const Tabla = ({ usuarios, validarUsuario }) =>{

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const [columnasusuarios, setColumnasUsuarios] = useState(
    [
      { title: "Matricula", field: "matricula" },
      { title: "Carrera", field: "carrera" },
      {
        title: "Nombre Completo",
        render: (row)=>
        <div>{row.nombre} {row.apellidos}</div>,
      },
      {
        title: "Tipo",
        render: (row)=> <div>
          {
            row.egresado === 0 ?
              <span class="badge badge-secundary">Alumno</span>
            :
              <span class="badge badge-secundary">Egresado</span>
          }
        </div>
      },
      {
        title: "Curriculum",
        render: (row)=>
                        <div>
                          {/* <button onClick={()=>setPdf(row.cv)} type="button" className="btn btn-default"><li style={{ color: 'green', fontSize: 25 }} className="fa fa-address-card"></li></button> */}
                          <a download='documento' href={row.cv} title='Descargar' ><li style={{ fontSize: 25}} className="fa fa-arrow-circle-down"></li></a>
                        </div>
      },
      {
        title: "",
        render: (row)=><div>
          {
            row.status === 1 ?
              <span class="badge badge-primary">Activo</span>
            :
              <span class="badge badge-danger">Inactivo</span>
          }
        </div>,
      },
      {
        render: (row)=><div>
        {
          parseInt(row.status) === 1 ?        
            <button onClick={()=>validarUsuario(0, row.usuario_id)} type="button" className="btn btn-default"><i style={{ color: 'red', fontSize: 25 }} class="fas fa-times-circle"></i></button>
          : 
            <button onClick={()=>validarUsuario(1, row.usuario_id)} type="button" className="btn btn-default"><i style={{ color: 'green', fontSize: 25 }}  className="fas fa-check-circle"></i></button>
        }
        </div>
      }
    ]
  );

  return(
    <div className="col-md-12">
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Lista de alumnos/egresados validados"
      columns={columnasusuarios}
      data={usuarios}
      onRowSelected
  />
  </div>
  )
}

export default Tabla