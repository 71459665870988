import React, { createContext, useState, useEffect } from 'react'

export const AppContext = createContext();

const AppProvider = (props) =>{
  const [admin, setAdmin] = useState(false);
  const [proyecto, setProyecto] = useState({
    usuario_id: 0,
    nombre_proyecto: '',
    desc_corta_negocio:'',
    capital_necesario: 0,
    tipo_alianza_id: 0,
    icono_logo: '',
    representante_proyecto:'',
    num_celular: '',
    correo: '',
    objetivo_general: '',
    integrantes_proyecto: '',
    pagina_web: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    linkedin: '',
    tiktok: '',
    pinterest: '',
    tiempo_con_negocio: '',
    experiencia_proyecto: '',
    fuentes_ingreso: '',
    ventas_anuales: 0,
    estrategia_clientes: '',
    ubicacion_proyecto: '',
    instalaciones: '',
    status: 0
  })

  const validadAdmin = async() =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(user){
      if(parseInt(user.admin)===1){
        setAdmin(true);
      }
    }
  }
  useEffect(()=>{
    validadAdmin();
  },[]);

  return(
    <AppContext.Provider
      value={{
        admin,
        proyecto,
        setProyecto
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider
