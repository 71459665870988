import React, { Fragment, useState, useEffect } from 'react'
import { getData, deleteItem } from '../../helpers'

import MenuAdministrador from '../../Template/MenuAdministrador'
import Formulario from './Formulario'
import Tabla from './Tabla'

const ContainerUniversidad = () =>{
  const [ciudades, setCiudades] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [universidad, setUniversidad]= useState({
    email: '',
    password: '',
    ciudad_id: 0,
    nombre_comercial: '',
    razon_social: '',
    rfc: '',
    direccion: '',
    logo: '',
    telefono: '',
    nombre_contacto: '',
    telefono_contacto: '',
    status: 1
  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getCiudades =  async () =>{
    const send = await getData('ciudades');
    console.log(send)
    if(send.status==="success"){
      setCiudades(send.datos);
    }
  }


  const getUsuarios =  async () =>{
    const send = await getData('usuarios');
    console.log(send)
    if(send.status==="success"){
      setUsuarios(send.datos);
    }
  }

  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setUniversidad(data);
    //setImagen(dominio+'/'+data.img)
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('universidades/'+data.universidad_id);
    console.log(send);
    if(send.code==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'No se eliminó la universidad',
        etiqueta: 'error'
      })
    }
    if(send.status==="success"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la universidad',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getUsuarios();
    }
  }


  useEffect(()=>{
    getCiudades();
    getUsuarios();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={4} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Usuarios</h2>
              <hr/>
            </div>
          </div>
          <Tabla 
            usuarios = {usuarios}

            actualiza = {actualiza}
            eliminaItem = {eliminaItem}

            mostrarmensaje2 = {mostrarmensaje2}

            botoneliminar = {botoneliminar}
            setBotonEliminar = {setBotonEliminar}

          />
        </div>
    </Fragment>
  )
}

export default ContainerUniversidad