import React, { Fragment, useState } from 'react'
import { insertData, updateItem } from '../../helpers'
import FileBase64 from 'react-file-base64';
import Mensaje from '../../Mensaje'

const Formulario = ( { promocion, setPromocion, getPromociones, empresas, admin }) =>{

  const { empresa_id, desc_promocion_empresa, imagen} = promocion;

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  
  const actualizaState = e =>{
    setPromocion({
      ...promocion,
      [e.target.name] : e.target.value
    });
  } 
  
  const getFiles =(file)=>{
    setMostrarMensaje({
      mostrar: false,
    })
    let formato = (file.base64).split(';');
    formato = (formato[0]).split('/');
    if((formato[1]).toString() !== 'png' && (formato[1]).toString() !== 'jpeg' && (formato[1]).toString() !== 'jpg'){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Tipo de archivo incorrecto',
        etiqueta: 'error'
      })
      return;
    }
    setPromocion({
      ...promocion,
      imagen: file.base64
    })
  }


  const submitFormulario = async e =>{
    e.preventDefault();

    //Validar
    if (empresa_id ===0 || desc_promocion_empresa === 0 || imagen==='' ){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Faltan campos(*) obligatorios',
        etiqueta: 'error'
      })
      return;
    }

    let ruta = 'promociones-empresa';
      const send = await insertData(ruta, promocion);
      console.log(send);
      if(send.status === "success"){
        setMostrarMensaje({
          mostrar: true,
          etiqueta: 'success',
          mensaje: 'La promocion se ha guardado correctamente'
        });
        setPromocion({
          empresa_id: promocion.empresa_id,
          desc_promocion_empresa: '',
          imagen: '',
          status: 0
        })
        getPromociones();
        return;
      }

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'No se guardó la convocatoria',
      etiqueta: 'error'
    })
  
  }

  return(
    <Fragment>
      <form 
        onSubmit = {submitFormulario}
      >
        <div className="row">
          <div className="col-md-12">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          </div>
          <div className="col-sm-6">
            {
              admin ? 
                <div className="form-group require_empresa_vac">
                  <label for="formGroupExampleInput2">Empresa*</label>
                  <select 
                  className="form-control"
                  name="empresa_id" 
                  value = {empresa_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Seleccione una empresa --</option>
                  {
                    empresas ?
                    empresas.map(item=>(
                      <option 
                        key = {item.empresa_id}
                        value={item.empresa_id}
                        >{item.nombre_comercial}
                      </option>
                    ))
                  :null
                  }
                  </select>
                </div>
              :
              null
            }
            <div className="form-group require_nom_comer_emp">
              <label for="formGroupExampleInput">Descripción Breve*</label>
              <textarea 
                rows = "5"
                name="desc_promocion_empresa" 
                value= {desc_promocion_empresa}
                onChange={actualizaState}
                className="form-control" 
                required
              />
            </div>
            <div className="form-group">
              <label >Imagen (png, jpg, jpeg)</label>
              <br />
              <FileBase64
                multiple={ false }
                onDone={ getFiles.bind(this) } />
            </div> 
            <div className="col-md-12">
              <button className="mt-1 btn btn-primary">Guardar promoción</button>
            </div>
          </div>
          <div className='col-md-6' >
            <img src={imagen} width="100%" />
          </div>
        </div>
        <hr/>
        <div className="row">
 
        </div>
      </form>
    </Fragment>
  )
}

export default Formulario