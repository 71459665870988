import React, { useState } from 'react'
import MaterialTable from 'material-table';
import Mensaje from '../../Mensaje'
import { CSVLink } from "react-csv";

const Tabla = ({ vacantes, actualiza, mostrarmensaje2, eliminaItem, botoneliminar, setBotonEliminar }) =>{

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const header = [
    { label: "Titulo", key: "titulo" },
    { label: "Descripción corta", key: "desc_corta_vacante" },
    { label: "Jornada", key: "jornada" },
    { label: "Salario", key: "salario" },
    { label: "Empresa", key: "nombre_comercial" }
  ];

  const [columnasvacantes, setColumnasVacantes] = useState(
    [
      { title: "Titulo", field: "titulo" },
      { title: "Descripción corta", field: "desc_corta_vacante" },
      { title: "Jornada", field: "jornada" },
      { title: "Salario", field: "salario" },
      { title: "Empresa", field: "nombre_comercial" },
      {
        title: "",
        render: (row)=><div>
        <button onClick={()=>actualiza(row)} type="button" className="btn btn-outline-warning btn-sm" data-tip="Editar">
          <li className="fa fa-edit"></li> 
        </button>
        <button onClick={()=>eliminaItem(true)} type="button" className="btn btn-outline-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#mediumModalEliminaCotizacion">
          <li className="fa fa-trash"></li>
        </button>
        {
          botoneliminar ?
          <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm"  >
            Confirmar
          </button>
          : null
        }
        </div>,
      },
      {
        title: "",
        render: (row)=><div>
          {
            parseInt(row.status_vacante) === 1 ?
            <label className="label label-danger">Activo</label>
            :
            <label className="label label-danger">Inactivo</label>
          }
        </div>,
      }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="offset-md-10 col-md-2 text-right">
        <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
            <li className="fa fa-cloud-download"></li> <CSVLink data={vacantes} headers={header} filename={"VacantesCanacintra.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
          </button> 
      </div>
      <div className="col-md-12">
        { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Lista de vacantes"
      columns={columnasvacantes}
      data={vacantes}
      onRowSelected
  />
  </div>
  )
}

export default Tabla