import React, { Fragment, useState, useEffect } from 'react'

import Menu from '../Template/Menu'

const Contacto = () =>{
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={4}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="wow animated fadeIn" data-wow-delay="0.15s"></div>
      <div id="fh5co-features" className="fh5co-features-outer">
        <div className="container">
          <h1 className="second-title" style={{ marginTop: 250 }}><span className="span-features">AVISO DE PRIVACIDAD</span></h1>
          <div className = "row">
            <p>
              Este Formato, sólo aplica para la Cámara Nacional de la Industria de Transformación y sus Delegaciones; el cual no es apto para empresas diversas a la Cámara, en virtud de que cada sociedad tiene requerimientos y especificaciones distintas que se debe contemplar en el aviso de privacidad de emitan
            </p>
            <h2>
              AVISO DE PRIVACIDAD
            </h2>
            <h3>
              RESPONSABLE: CÁMARA NACIONAL DE LA INDUSTRIA DE TRANSFORMACIÓN, (CANACINTRA).
            </h3>
            <p>
              En atención y cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, la CÁMARA NACIONAL DE LA INDUSTRIA DE TRANSFORMACIÓN, (CANACINTRA), Delegación Tabasco,  con domicilio en Calle Vía 2 No. 112, Colonia Tabasco 2000, Villahermosa, Centro, Tabasco, reconoce que es responsable de la privacidad y seguridad en el tratamiento de los datos personales proporcionados a esta Institución, conforme al presente aviso de privacidad, señalando como encargado de la protección de los datos personales a: Lic. Carla Emilia Morales Ariza.
            </p>
            <h3>DE LOS DATOS Y OBJETIVOS.</h3>
            <p>
            CANACINTRA, como representante de los industriales de transformación, solicita y obtiene algunos datos personales de los industriales que se afilian a ella, ya sea como empresas o personas físicas en su calidad de empresarios, solicitando entre otros datos, nombre o razón social de la empresa, datos de su domicilio, RFC, teléfono, e-mail, CURP, nombre del representante ante CANACINTRA, giro o actividad principal del establecimiento, rango de ventas brutas, productos o servicios que ofrece, insumos que demanda; lo anterior con la finalidad de que CANACINTRA los agrupe en la Rama y Sector Industrial correspondiente y pueda debidamente representar, defender y promover los intereses generales de la Industria de Transformación; ser órgano de consulta y colaboración del Estado para el diseño, divulgación y ejecución de programas que faciliten la expansión de la actividad económica; promover las actividades y los sistemas que conduzcan a mejorar las relaciones entre patrones y trabajadores y las medidas convenientes para facilitar los procesos de formación y capacitación de los recursos humanos del sector productivo, así como la adopción de acciones orientadas a medir e incrementar la productividad en las empresas afiliadas; e identificar y apoyar actividades vinculadas con el desarrollo socioeconómico; realizar estadísticas o encuentras, enviar promociones e información, y entregar información a dependencias gubernamentales
            </p>

            <h3>DE LA AUTORIZACIÓN.</h3>
            <p>
            Al afiliarse o registrarse los empresarios e industriales a CANACINTRA, autorizan a ésta a recabar sus datos personales para los fines señalados en el párrafo anterior, lo cual constituye su conformidad y aceptación del aviso de privacidad y las posibles modificaciones que pudieran darse, mismas que se publicarán en su caso en su página de Internet que más adelante se señala.
            </p>
            <h3>
            LIMITACIÓN DE USO Y DIVULGACIÓN DE LOS DATOS PERSONALES.
            </h3>
            <p>
            Para limitar el uso de sus datos personales, así como para rectificar u oponerse al tratamiento de los mismos, le solicitamos al titular ponerse en contacto mediante correo electrónico con Nadia Vanessa Gómez Reyes a la dirección de correo electrónico tabasco@canacintra.org.mx o mediante escrito dirigido a la misma persona como encargado de la protección de los datos personales, con domicilio en Calle Vía 2 No. 112, Colonia Tabasco 2000, Villahermosa, Centro, Tabasco
            </p>
            <h3>
            MEDIOS PARA EJERCER DERECHOS ARCO (ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN).
            </h3>
            <p>
            Asimismo, el titular puede solicitar el acceso a los datos personales, así como rectificarlos, cancelarlos u oponerse cuando considere que son inexactos, incompletos o no se requieren, para los fines y objetivos señalados en el presente aviso de privacidad, por considerar que están siendo utilizados para objetivos no previstos o por haber concluido estatutariamente sus derechos como afiliado, lo anterior mediante solicitud por escrito que contenga la siguiente información: nombre del titular; domicilio o dirección de correo electrónico para comunicar la respuesta a su solicitud; documentos que acrediten identidad o autorización para presentar la solicitud; descripción de datos personales sobre los que se pretende ejercer algún derecho ARCO; cualquier otro elemento que permita la localización de los datos personales y atención de la solicitud, a la dirección y persona antes mencionada.
            </p>
            <h3>
            TRANSFERENCIA DE DATOS.
            </h3>
            <p>
            Sus datos personales pueden ser transferidos, para alcanzar los objetivos de representación y promoción de las actividades industriales, por parte de terceros; si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.
            </p>
            <p>
            Asimismo, se ratifica que los únicos casos en que la responsable cumpliría con la obligación de revelar los datos proporcionados, es mediante orden o solicitud escrita de autoridad competente y las demás previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
            </p>
            <h3>
            SIEM.
            </h3>
            <p>
            Se hace del conocimiento de los empresarios, que de conformidad con el Título Tercero, de la Ley de Cámaras Empresariales y sus Confederaciones, la información que se recabe por parte del Sistema de Información Empresarial Mexicano, es responsabilidad de la Secretaría de Economía; la cual se rige por lo dispuesto en la Ley Federal de Transparencia y Acceso a la Información Pública Gubernamental.
            </p>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default Contacto