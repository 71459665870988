import React, { Fragment, useState, useEffect } from 'react'
import { insertData, getData } from '../helpers'
import Mensaje from '../Mensaje'
import { useLocation } from 'react-router-dom'
import ReactPlayer from "react-player";

import ModalMeInteresa from '../ModalMeInteresa'

import Menu from '../Template/Menu'


const Vacantes = () =>{
  const [usuario, setUsuario] = useState(null);
  const [empresa, setEmpresa] = useState('');
  const [postulado, setPostulado] = useState(false);
  const [numinteresados, setNumInteresados] = useState(0);
  const [videos, setVideos] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const [proyecto, setProyecto] = useState([]);

  const getVacante =  async () =>{
    let id = query.get("id");
    if(!id || id ==='null'){
      window.location = '/proyectos';
    }
    const send = await getData('proyectos/'+id);
    if(send.status==="success"){
      setProyecto(send.datos);
    }
  }

  const getInteresados =  async () =>{
    let id = query.get("id");
    const send = await getData('interesado-proyectos/'+id);
    if(send.status==="success"){
      setNumInteresados(send.datos.length);
    }
  }

  const getImagenes =  async () =>{
    let id = query.get("id");
    const send = await getData('fotos-proyecto/'+id);
    if(send.status==="success"){
      setImagenes(send.datos);
    }
  }

  const getVideos =  async () =>{
    let id = query.get("id");
    const send = await getData('videos-de-proyecto/'+id);
    if(send.status==="success"){
      setVideos(send.datos);
    }
  }

  const validaPostulante = async() =>{
    let proyecto_id = query.get("id");
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(user){
      if(!user.empresa_id){
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Para aplicar, debe ser un usuario empresa.',
          etiqueta: 'error'
        })
        return;
      }
      let ruta = 'interesado';
      let interesado = {
        empresa_id: user.empresa_id,
        proyecto_id: proyecto_id,
        status: 0
      }
      const send = await insertData(ruta,interesado);
      if(send.status==="success"){
        setPostulado(true);
        return;
      }
    }
  }

  const postularProyecto = async() => {
    let id_vacante = query.get("id");
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(!user.empresa_id){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'No puedes postularte a esta vacante, debe ser un usuario.',
        etiqueta: 'error'
      })
      return;
    }
    let ruta = 'interesado-proyectos';
    let postulante = {
      empresa_id: user.empresa_id,
      proyecto_id: id_vacante,
      status: 0
    }
    const send = await insertData(ruta,postulante);
    if(send.status === "success"){
      setPostulado(true);
      getInteresados();
      return;
    }
  }

  useEffect( async ()=>{
      const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
      if(user){
          setEmpresa(user.empresa_id);
          setUsuario(user);
      }
      getVacante();
      validaPostulante();
      getInteresados();
      getImagenes();
      getVideos();
    },[]);  

  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={4}  />
      </div> 
      <ModalMeInteresa 
        postularProyecto = {postularProyecto}
        postulado = {postulado}
      />
      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className=" wow animated fadeIn" data-wow-delay="0.15s" style={{ backgroundImage: proyecto.img ? `url(${proyecto.img})` :  `url("img/proyecto_img.png")`, height: 300, backgroundPosition: 'center', backgroundSize: 'cover'}}></div>
      <div id="fh5co-features" style={{ position: 'relative' }} className="fh5co-features-outer">
        <div className="container">
          <div className="icono-proyecto text-center">
            <img width= "150" className="grid-image" src= {proyecto.icono_logo === '' ? 'img/default_img.png' : proyecto.icono_logo} />
          </div>
          <h1 className="second-title"><span className="span-features"> { proyecto.nombre_proyecto } </span></h1>
            <div className="row">
              <div className="col-md-3" style={{ color: 'gray' }}>
              {/* <li className="fa fa-eye"></li> 12  */}
              <li className="fa fa-star"></li> {numinteresados}
              </div>
            </div>
          <hr/>
        <div className="row">
        <div className="col-md-12 text-center">
          {
            !usuario ? 
              <button className="col-md-12 btn btn-md features-btn-first animated fadeInLeft wow btn-primary" data-wow-delay="0.95s" data-toggle="modal" data-target="#modalSesion">Debe iniciar sesión para ver el detalle</button>
            : 
              null
          }
          </div>
          <div className="col-md-8">
            <div>
                <h5>Objetivo general</h5>
                {
                  proyecto.objetivo_general
                }
            </div>
            <hr/>
            <div>
              <h5>Descripción</h5>
                {
                  proyecto.desc_corta_negocio
                }
            </div>
            <hr style={{ marginTop: 100 }} />
            <div>
              <h5>Galería de imágenes</h5>
              <div>
                {
                  imagenes ?
                    imagenes.map(item=>(
                      <div style={{ padding: 10, float: 'left', width: '50%' }}>
                        <img width="100%" src={ item.foto_base64 } />
                      </div>
                    ))
                  : null
                }
              </div>
            </div>
            <hr/>
            <div>
              <h5>Galería de videos</h5>
              {
                  videos ?
                    videos.map(item=>(
                      <ReactPlayer
                        url = { item.url }
                      />
                      // <div style={{ padding: 5, float: 'left', width: '50%' }}>
                      //   <iframe src={ item.url }
                      //     frameBorder='0'
                      //     allow='autoplay; encrypted-media'
                      //     allowFullScreen
                      //     title='video'
                      //     width = '100%'
                      //     height = '200px'
                      //     />
                      // </div>
                    ))
                  : null
                }
            </div>
            <div style={{ marginTop: 100, float: 'none' }}>
              <h5>Redes sociales</h5>
              <div style={{ float: 'left', paddingLeft: 10 }}><img width= "30" className="grid-image" src='img/redes_sociales/facebook.png' /></div>
              <div style={{ float: 'left', paddingLeft: 10 }}><img width= "30" className="grid-image" src='img/redes_sociales/twitter.png' /></div>
              <div style={{ float: 'left', paddingLeft: 10 }}><img width= "30" className="grid-image" src='img/redes_sociales/instagram.png' /></div>
              <div style={{ float: 'left', paddingLeft: 10 }}><img width= "30" className="grid-image" src='img/redes_sociales/youtube.png' /></div>
              <div style={{ float: 'left', paddingLeft: 10 }}><img width= "30" className="grid-image" src='img/redes_sociales/linkedin.png' /></div>
              <div style={{ float: 'left', paddingLeft: 10 }}><img width= "30" className="grid-image" src='img/redes_sociales/tiktok.png' /></div>
            </div>
            <div style={{ marginBottom: 200, marginTop: 100 }} className="container fh5co-hero-inner">
              <button onClick={()=>{window.location = '/proyectos'}} className=" col-md-3 btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s"><li className="fa fa-chevron-left"></li> Regresar</button>
            </div>
          </div>
          <div className="col-md-4">
          <h3>Datos de contacto</h3>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Representante: </b> { proyecto.representante_proyecto  } </p>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Celular: </b> { proyecto.num_celular  } </p>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Correo: </b> { proyecto.correo  } </p>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Página web: </b> <a href={ proyecto.pagina_web } > { proyecto.pagina_web } </a></p>
            <hr />
            <h3>Resumen</h3>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Publicado: </b>{ proyecto.fecha_registro }</p>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Ventas anuales: </b> ******** </p>
            <p><small>Proprocionado en entrevista</small></p>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Tiempo con el negocio: </b> { proyecto.tiempo_con_negocio  } </p>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Ubicación: </b> { proyecto.ubicacion_proyecto }</p>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Fuente de ingresos: </b> { proyecto.fuentes_ingreso }</p>
            <p style={{ marginBottom: 0, marginTop:10 }}><b>Experiencia en el negocio: </b> { proyecto.experiencia_proyecto }</p>
            <hr/>
            <h3>Integrantes</h3>
            <p style={{ marginBottom: 0, marginTop:10 }}>{ proyecto.integrantes_proyecto }</p>
            <hr/>
            <div className="container fh5co-hero-inner">

            {
                !postulado ?
                  empresa ?
                    <button style={{ backgroundColor: 'green' }} className=" col-md-12 btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" data-toggle="modal" data-target="#modalPostularse"><li className="fa fa-star"></li> ¡ Me interesa!</button>
                  : null
                :
                  <button style={{ backgroundColor: 'gray' }} className="col-md-12 btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" >¡Ya está interesado en este proyecto!</button>
              }
              { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
            </div>
          </div>
        </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Vacantes