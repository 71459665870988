import React from 'react'

const Item = ( { item } ) =>{
  return(
    <div className="row">
      <div style={{  marginTop: 20, marginBottom: 10}} className="col-sm-12 in-order-2 wow animated fadeInRight" data-wow-delay="0.22s">
        <h3 className="titulo-vacante"><a href={'vacante?id='+item.vacante_id}>{item.titulo}</a></h3>
        <h5 className="empresa-vacante">{item.nombre_comercial}</h5>
        <p>{item.desc_corta_vacante}</p>
        <span className="circle circle-first fecha-vacante">Fecha: {item.fecha_publicacion}</span>
        <hr/>
      </div>
    </div> 
  )
}

export default Item