import React, {useState} from 'react';
import { login } from './helpers'
import Mensaje from './Mensaje'

const Modal = () => {
  const [user, actualizaUser] = useState({
    email:'',
    password: ''
  })

  //Extraer valores
  const {email, password} = user;

  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });
  const {message, label} = mensaje;


  const actualizaState = e =>{
    actualizaUser({
      ...user,
      [e.target.name] : e.target.value
    })
  }


  const submitFormulario = async e =>{
    e.preventDefault();
    console.log('Iniciando sesión....');
    const sendUser = await login('login',user);
    
    console.log(sendUser);
    if(sendUser.usuario){
      if(parseInt(sendUser.activo) === 1){
        localStorage.setItem('CANA_USERTOKEN',sendUser.access_token);
        const user = JSON.stringify(sendUser.usuario)
        localStorage.setItem('CANA_USER',user);
        // setCookie('STORAGE_KEY', sendUser.access_token);
        if(parseInt(sendUser.usuario.admin) === 1){
          window.location = '/admin-vacantes';
          return;
        }else{
          window.location = '/';
          return;
        }
      }else{
        actualizaMensaje({
          status:true,
          label: "error",
          message: 'Este usuario aún no ha sido válidado'
        })
        return;
      }
    }
    actualizaMensaje({
      status:true,
      label: "error",
      message: sendUser.message
    })
  }

  
  return (
    <div id="modalSesion" className="modal fade" role="dialog">
      <form
        onSubmit={submitFormulario}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Iniciar Sesión</h5>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              {mensaje.status ? <Mensaje etiqueta={label} mensaje={message} />: null}
              <div className="form-group">
                <p>Correo</p>
                <input id="email" type="email" className="form-control col-md-12" name="email" value={email} onChange={actualizaState}  required/>
              </div>
              <div className="form-group">
                <p>Contraseña</p>
                <input id="password" type="password" className="form-control col-md-12" name="password" value={password} onChange={actualizaState}  required  />
              </div>
            </div>
            <button className="mt-1 btn btn-primary">Acceder</button>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Modal;
