import React, { useState, useContext } from 'react';
import { insertData, updateItem } from './helpers'
import Mensaje from './Mensaje'
import FileBase64 from 'react-file-base64';

import {AppContext} from './contextApp'

const Modal = ( { getProyectos, actualizar } ) => {
  const {proyecto, setProyecto} = useContext(AppContext);
  //Extraer valores
  const {
    usuario_id, 
    nombre_proyecto, 
    desc_corta_negocio, 
    capital_necesario,
    icono_logo,
    representante_proyecto,
    num_celular,
    correo,
    objetivo_general,
    integrantes_proyecto,
    pagina_web,
    facebook,
    instagram,
    twitter,
    youtube,
    linkedin,
    tiktok,
    pinterest,
    tiempo_con_negocio,
    experiencia_proyecto,
    fuentes_ingreso,
    ventas_anuales,
    estrategia_clientes,
    ubicacion_proyecto,
    instalaciones,
    status
  } = proyecto;

  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });
  const {message, label} = mensaje;


  const actualizaState = e =>{
    setProyecto({
      ...proyecto,
      [e.target.name] : e.target.value
    })
  }

  const getFiles =(file)=>{
    let archivo = (file.base64).split(';');
    console.log(archivo[0]);
    if(archivo[0] === 'data:image/jpeg' || archivo[0] === 'data:image/png'){
      actualizaMensaje({
        status:false,
      })
      setProyecto({
        ...proyecto,
        icono_logo: file.base64
      })
      return;
    }
    actualizaMensaje({
      status:true,
      label: "error",
      message: 'Formato permitido de imagen es: png, jpg, jpeg'
    })
  }

  const submitFormulario = async e =>{
    e.preventDefault();

    console.log('Registrando....');
    if(nombre_proyecto === '' || desc_corta_negocio === ''){
      actualizaMensaje({
        status:true,
        label: "error",
        message: "Debe completar todos los campos (*)"
      });
      return;
    }
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    let datos_proyecto = {
      usuario_id: user.usuario_id,
      nombre_proyecto: proyecto.nombre_proyecto,
      desc_corta_negocio: proyecto.desc_corta_negocio,
      capital_necesario: proyecto.capital_necesario,
      tipo_alianza_id: 1,
      icono_logo: proyecto.icono_logo,
      representante_proyecto:proyecto.representante_proyecto,
      num_celular: proyecto.num_celular,
      correo: proyecto.correo,
      objetivo_general: proyecto.objetivo_general,
      integrantes_proyecto: proyecto.integrantes_proyecto,
      pagina_web: proyecto.pagina_web,
      facebook: proyecto.facebook,
      instagram: proyecto.instagram,
      twitter: proyecto.twitter,
      youtube: proyecto.youtube,
      linkedin: proyecto.linkedin,
      tiktok: proyecto.tiktok,
      pinterest: proyecto.pinterest,
      tiempo_con_negocio: proyecto.tiempo_con_negocio,
      experiencia_proyecto: proyecto.experiencia_proyecto,
      fuentes_ingreso: proyecto.fuentes_ingreso,
      ventas_anuales: proyecto.ventas_anuales,
      estrategia_clientes: proyecto.estrategia_clientes,
      ubicacion_proyecto: proyecto.ubicacion_proyecto,
      instalaciones: proyecto.instalaciones,
      status: proyecto.status,
    }
    console.log(datos_proyecto);
    let send = '';
    if(actualizar){
      send = await updateItem('proyectos/'+proyecto.proyecto_id,proyecto);
    }else{
      send = await insertData('proyectos',datos_proyecto);
    }
    if(send.status === "success"){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha registrado correctamente."
      });
      setProyecto({
        usuario_id: 0,
        nombre_proyecto: '',
        desc_corta_negocio:'',
        capital_necesario: 0,
        tipo_alianza_id: 0,
        icono_logo: '',
        representante_proyecto:'',
        num_celular: '',
        correo: '',
        objetivo_general: '',
        integrantes_proyecto: '',
        pagina_web: '',
        facebook: '',
        instagram: '',
        twitter: '',
        youtube: '',
        linkedin: '',
        tiktok: '',
        pinterest: '',
        tiempo_con_negocio: '',
        experiencia_proyecto: '',
        fuentes_ingreso: '',
        ventas_anuales: 0,
        estrategia_clientes: '',
        ubicacion_proyecto: '',
        instalaciones: '',
        status: 0
      })
      getProyectos();
      return;
    }
    actualizaMensaje({
      status:true,
      label: "error",
      message: 'Ha ocurrido un error.'
    })
  }

  return (
    <div id="modalRegistroProyectos" className="modal fade" role="dialog">
      <form
        onSubmit={submitFormulario}
      >
        <div class="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Agregar proyecto</h5>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              {mensaje.status ? <Mensaje etiqueta={label} mensaje={message} />: null}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <p>Nombre del proyecto</p>
                      <input 
                      type= "text"
                      name="nombre_proyecto" 
                      value= {nombre_proyecto}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      required
                      />
                    </div>
                    <div className="form-group">
                      <p>Representante</p>
                      <input 
                      type= "text"
                      name="representante_proyecto" 
                      value= {representante_proyecto}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      required
                      />
                    </div>
                    <div className="form-group">
                      <p>Celular</p>
                      <input 
                      type= "text"
                      name="num_celular" 
                      value= {num_celular}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      
                      />
                    </div>
                    <div className="form-group">
                      <p>Email</p>
                      <input 
                      type= "text"
                      name="correo" 
                      value= {correo}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      required
                      />
                    </div>
                    <div className="form-group">
                      <p>Descripción del negocio</p>
                      <textarea 
                      type= "text"
                      rows = {3}
                      name="desc_corta_negocio"
                      value= {desc_corta_negocio}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      required
                      />
                    </div>
                    <div className="form-group">
                      <p>Objetivo general</p>
                      <textarea 
                      type= "text"
                      rows = {3}
                      name="objetivo_general"
                      value= {objetivo_general}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      required
                      />
                    </div>
                    <div className="form-group">
                      <p>Integrantes</p>
                      <textarea 
                      type= "text"
                      rows = {3}
                      name="integrantes_proyecto"
                      value= {integrantes_proyecto}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div>
                    <div className="form-group">
                      <p>Estrategía para atrer clientes</p>
                      <textarea 
                      type= "text"
                      rows = {3}
                      name="estrategia_clientes"
                      value= {estrategia_clientes}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div>
                    <div className="form-group">
                      <p>Página web</p>
                      <input 
                      type= "text"
                      name="pagina_web" 
                      value= {pagina_web}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div>
                    {/* <div className="form-group">
                      <p>Capital necesario</p>
                      <input 
                      type= "number"
                      name="capital_necesario" 
                      value= {capital_necesario}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div> */}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <img width= "150" className="grid-image" src= {proyecto.icono_logo === '' ? 'img/default_img.png' : proyecto.icono_logo} />
                      <p>Logotipo</p>
                      <FileBase64
                        multiple={ false }
                        onDone={ getFiles.bind(this) } />
                    </div>
                    <div className="form-group">
                      <p>Tiempo con el negocio/proyecto</p>
                      <input 
                      type= "text"
                      name="tiempo_con_negocio" 
                      value= {tiempo_con_negocio}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div>
                    <div className="form-group">
                      <p>Fuentes de ingreso</p>
                      <input 
                      type= "text"
                      name="fuentes_ingreso" 
                      value= {fuentes_ingreso}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div>
                    <div className="form-group">
                      <p>Ventas anuales</p>
                      <input 
                      type= "number"
                      name="ventas_anuales" 
                      value= {ventas_anuales}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div>
                    <div className="form-group">
                      <p>Ubicación del proyecto</p>
                      <input 
                      type= "text"
                      name="ubicacion_proyecto" 
                      value= {ubicacion_proyecto}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div>
                    <div className="form-group">
                      <p>Instalaciones</p>
                      <select 
                        name="instalaciones" 
                        value = {instalaciones}
                        onChange = {actualizaState}
                        className="form-control form-control-sm">
                        <option value ="Propias">Propias</option>
                        <option value ="Prestadas">Prestadas</option>
                        <option value ="Aquiler">Aquiler</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <p>¿Que tipo de apoyo consideras para potenciar tu negocio?</p>
                      <select 
                        // name="egresado" 
                        // value = {egresado}
                        onChange = {actualizaState}
                        className="form-control form-control-sm">
                        <option value ="Financiamiento">Financiamiento</option>
                        <option value ="Canales de distribución">Canales de distribución</option>
                        <option value ="Coach/Mentor">Coach/Mentor</option>
                        <option value ="Tramites y/o soluciones (registro de marca, legal, etiquetados, código de barras, etc)">Tramites y/o soluciones (registro de marca, legal, etiquetados, código de barras, etc)</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <p>¿Cual es la experiencia de tu negocio al momento que emprendiste?</p>
                      <textarea 
                      type= "text"
                      rows = {3}
                      name="experiencia_proyecto"
                      value= {experiencia_proyecto}
                      onChange={actualizaState}
                      className="form-control form-control-sm" 
                      />
                    </div>
                  </div>
                  <div className ="col-md-12">
                    <div className="row">
                      <div className="form-group col-md-12">
                        <h5>Redes Sociales</h5>
                        <hr/>
                      </div>
                      <div className="form-group col-md-4">
                        <p>Facebook</p>
                        <input 
                        type= "text"
                        name="facebook" 
                        value= {facebook}
                        onChange={actualizaState}
                        className="form-control form-control-sm" 
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <p>Instagram</p>
                        <input 
                        type= "text"
                        name="instagram" 
                        value= {instagram}
                        onChange={actualizaState}
                        className="form-control form-control-sm" 
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <p>Twitter</p>
                        <input 
                        type= "text"
                        name="twitter" 
                        value= {twitter}
                        onChange={actualizaState}
                        className="form-control form-control-sm" 
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <p>Youtube</p>
                        <input 
                        type= "text"
                        name="youtube" 
                        value= {youtube}
                        onChange={actualizaState}
                        className="form-control form-control-sm" 
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <p>Linkedin</p>
                        <input 
                        type= "text"
                        name="linkedin" 
                        value= {linkedin}
                        onChange={actualizaState}
                        className="form-control form-control-sm" 
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <p>Tiktok</p>
                        <input 
                        type= "text"
                        name="tiktok" 
                        value= {tiktok}
                        onChange={actualizaState}
                        className="form-control form-control-sm" 
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <p>Pinterest</p>
                        <input 
                        type= "text"
                        name="pinterest" 
                        value= {pinterest}
                        onChange={actualizaState}
                        className="form-control form-control-sm" 
                        />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            {
              actualizar ? 
                <button className="mt-1 btn btn-warning">Actualizar</button>
              :
                <button className="mt-1 btn btn-primary">Agregar</button>
            }
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
      
        </div>
      </form>
    </div>
  );
}

export default Modal;
