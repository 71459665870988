import React, { Fragment, useState, useEffect, useContext } from 'react'
import { getData, deleteItem } from '../../helpers'
import Formulario from './Formulario'
import FormularioImagen from './FormularioImagen'
import Tabla from './Tabla'
import Mensaje from '../../Mensaje'

import MenuAdministrador from '../../Template/MenuAdministrador'

const ContainerRegistrarFecha = () =>{
  const [fechas, setFechas] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [imagen, setImagen] = useState({
    img: '',
    status: 0
  });

  const [fecha, setFecha] = useState({
    universidad_id: 0,
    tipo: 'practicas',
    fecha_inicio: '',
    fecha_fin: '',
    status: 0
  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const [mostrarmensaje3, setMostrarMensaje3]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getFechas =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    setFecha({
      ...fecha,
      universidad_id: user.universidad_id
    })
    let ruta = 'registrar-fecha';
    const send = await getData(ruta+'/'+user.universidad_id);
    console.log(send)
    if(send.status==="success"){
      setFechas(send.datos);
    }
  }

  const getImagenes =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    let ruta = 'imagen-fecha';
    const send = await getData(ruta+'/'+user.universidad_id);
    console.log(send)
    if(send.status==="success"){
      setImagenes(send.datos);
    }
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('registrar-fecha/'+data.fecha_universidad_id);
    console.log(send);
    if(send.code==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'No se eliminó la fecha',
        etiqueta: 'error'
      })
    }
    if(send.status==="success"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la fecha',
        etiqueta: 'danger'
      })
      getFechas();
    }
  }

  const eliminaItemImagen = async (data) =>{
    const send = await deleteItem('imagen-fecha/'+data.imagen_fecha_id);
    console.log(send);
    if(send.code==="error"){
      setMostrarMensaje3({
        mostrar: true,
        mensaje: 'No se eliminó la imagen',
        etiqueta: 'error'
      })
    }
    if(send.status==="success"){
      setMostrarMensaje3({
        mostrar: true,
        mensaje: 'Se ha eliminado la imagen',
        etiqueta: 'danger'
      })
      getImagenes();
    }
  }
  useEffect(()=>{
    getFechas();
    getImagenes();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={8} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Registrar fechas</h2>
              <hr/>
            </div>
            <div className="col-md-12">
              <Formulario 
                fecha = {fecha}
                setFecha = {setFecha}

                getFechas = {getFechas}
              />
            </div>
            <div className="col-md-12">
              <Tabla 
                fechas = {fechas}
                eliminaItem = {eliminaItem}
                mostrarmensaje2 = {mostrarmensaje2}
              />
            </div>
            <div className="col-sm-12">
              <br/>
              <h2>Subir imagenes</h2>
              <hr/>
            </div>
            <div className="col-md-12">
              <FormularioImagen 
                imagen = {imagen}
                setImagen = {setImagen}
                getImagenes = {getImagenes}
              />
            </div>
            <div className="col-md-12 text-center">
            { mostrarmensaje3.mostrar ? <Mensaje mensaje = {mostrarmensaje3.mensaje} etiqueta = {mostrarmensaje3.etiqueta} /> : null}
              {
                imagenes.length > 0 ?
                  imagenes.map(item=>(
                    <div style = {{ margin: 50 }}>
                      <img width = "500" src = {item.img} />
                      <div>
                        <button onClick={()=>eliminaItemImagen(item)}  className="btn btn-md btn-danger features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" >Eliminar</button>
                      </div>
                    </div>
                  ))
                :
                null
              }
            </div>
          </div>
        </div>
    </Fragment>
  )
}

export default ContainerRegistrarFecha