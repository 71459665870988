import React, { Fragment, useEffect, useState } from 'react'

const Menu = ({activar}) =>{

  const [tipo, setTipo] = useState('');
  const [usuario, setUsuario] = useState(null);

  useEffect( async ()=>{
      const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
      console.log(usuario);
      if(user){
          setTipo(user.tipo);
          setUsuario(user);
      }
    },[]);  

    const cerrarSesion= async()=>{
      //document.cookie = "STORAGE_KEY=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem('CANA_USERTOKEN');
      localStorage.removeItem('CANA_USER');
      window.location = '/';
    }

  return(
    <Fragment>
      <nav className="container navbar navbar-expand-lg main-navbar-nav navbar-light">
        <a className="navbar-brand" href="./"><img src="img/logo_canacintra.png" width="250" /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav nav-items-center ml-auto mr-auto">
            <li className={activar === 1 ? 'nav-item active' : 'nav-item'} >
              <a className="nav-link" href="/">Inicio <span className="sr-only">(current)</span></a>
            </li>
            <li className={activar === 2 ? 'nav-item active' : 'nav-item'} >
              <a className="nav-link" href="vacantes-practicas"  >Prácticas/Servicio/Vacantes</a>
            </li>
            {/* <li className={activar === 3 ? 'nav-item active' : 'nav-item'} >
              <a className="nav-link" href="vacantes-servicio"  >Sevicio Social</a>
            </li> */}
            <li className={activar === 4 ? 'nav-item active' : 'nav-item'} >
              <a className="nav-link" href="proyectos"  >Proyectos</a>
            </li>
            <li className={activar === 5 ? 'nav-item active' : 'nav-item'} >
              <a className="nav-link" href="universidades" >Universidades</a>
            </li>
            <li className={activar === 6 ? 'nav-item active' : 'nav-item'} >
              <a className="nav-link" href="empresas"  >Empresas</a>
            </li>
            <li className={activar === 7 ? 'nav-item active' : 'nav-item'} >
              <a className="nav-link" href="contacto"  >Contacto</a>
            </li>
            <li className={activar === 8 ? 'nav-item active' : 'nav-item'} >
              {
                usuario ?
                <a className="nav-link" href="#" style={{backgroundColor: 'red'}} onClick={()=>cerrarSesion()} >Cerrar sesión</a>
                :
                <a className="nav-link" href="#" style={{backgroundColor: 'red'}} data-toggle="modal" data-target="#modalSesion" >Iniciar Sesión</a>
              }
              
            </li>
          </ul>
          <div className="social-icons-header">
            {
              usuario ? 
              <a href={ usuario.empresa_id  ? 
                '/admin-vacantes' : 
                usuario.universidad_id ? 
                '/admin-datos-universidad'
                :
                '/admin-usuario'}>
                <i style={{ fontSize: 30}} className="fa fa-user"></i></a>
              :
              null
            }
            {/* <a href="https://www.facebook.com/CanacintraDelegacionTabasco/" target="new"><i className="fab fa-facebook-f"></i></a> */}
            {/* <a href="#"><i className="fab fa-instagram"></i></a> */}
            {/* <a href="https://twitter.com/CANACINTRA_Tab" target="new" ><i className="fab fa-twitter"></i></a> */}
          </div>
        </div>
      </nav>
    </Fragment>    
  );
}

export default Menu