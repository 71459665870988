import React, { useState } from 'react'
import MaterialTable from 'material-table';
import Mensaje from '../../Mensaje'


const Tabla = ({ fechas, eliminaItem, mostrarmensaje2 }) =>{

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const [columnasusuarios, setColumnasUsuarios] = useState(
    [
      { title: "Tipo", field: "tipo" },
      { title: "Fecha inicio", field: "fecha_inicio" },
      { title: "Fecha fin", field: "fecha_fin" },
      {
        title: "",
        render: (row)=>
                <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#mediumModalEliminaCotizacion">
                  <li className="fa fa-trash"></li>
                </button>
      }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="col-md-12">
        { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
      </div>      
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 5
      }}
      title="Fechas registradas"
      columns={columnasusuarios}
      data={fechas}
      onRowSelected
  />
  </div>
  )
}

export default Tabla