import React from 'react'

const Item = ( { item } ) =>{
  return(
    <div className="row" style={{  marginTop: 100, marginBottom: 10}}>
      <div className="col-md-3 animated fadeInLeft text-right">
        {
          item.icono_logo ?
          <img height="120" src={item.icono_logo} />
          :
          <img height="120" src="img/proyecto_img.png" />
        }
      </div>
      <div style={{  marginBottom: 10}} className="col-sm-9 in-order-2 wow animated fadeInRight" data-wow-delay="0.22s">
        <h3 className="titulo-vacante"><a href={'proyecto?id='+item.proyecto_id}>{ item.nombre_proyecto }</a></h3>
        {/* <li className="fa fa-eye"></li> 12 
        <li className="fa fa-star"></li> 2 */}
        <p>{ item.objetivo_general }</p>
        <p> { item.desc_corta } </p>
        <span className="circle circle-first fecha-vacante">Fecha: { item.fecha_registro }</span>
        <hr/>
      </div>
    </div> 
  )
}

export default Item