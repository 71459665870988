import React from 'react';

const Modal = ( { status, setMostrarMensaje } ) => {
  return (
    <div id="modalPostularse" 
      className= { status ? "modal fade show"  : "modal fade" }
      style = {{ display: status ? 'block' : 'none' }}
      role="dialog">
      <form>
        <div className="modal-dialog">
          <div className="modal-content text-center">
            <img style={{ alignSelf: 'center', paddingTop: 20, paddingBottom: 20 }} src="img/check.gif" width="150" />
            <div className="modal-footer">
              <button onClick={ ()=>setMostrarMensaje(false) } type="button" className="btn btn-primary" data-dismiss="modal">Aceptar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Modal;
