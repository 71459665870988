import React, { useState } from 'react'
import MaterialTable from 'material-table';
import Mensaje from '../../Mensaje'
import { CSVLink } from "react-csv";

const Tabla = ({ universidades, actualiza, mostrarmensaje2, eliminaItem}) =>{
  const [botoneliminar, setBotonEliminar] = useState(false);
  
  const statusEliminar = async (data) =>{
    console.log('status elimis');
    setBotonEliminar(true);
  }
  
  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const header = [
    { label: "Correo", key: "email" },
    { label: "Nombre", key: "nombre_comercial" },
    { label: "Giro", key: "desc_giro" },
    { label: "Dirección", key: "direccion" },
    { label: "Teléfono", key: "telefono" },
    { label: "Contacto", key: "nombre_contacto" },
    { label: "Telefono contacto", key: "telefono_contacto" }
  ];

  const [columnasuniversidades, setColumnasUniversidades] = useState(
    [
      { title: "Correo", field: "email" },
      { title: "Nombre", field: "nombre_comercial" },
      { title: "Giro", field: "desc_giro" },
      { title: "Dirección", field: "direccion" },
      { title: "Teléfono", field: "telefono" },
      { title: "Contacto", field: "nombre_contacto" },
      { title: "Telefono contacto", field: "telefono_contacto" },
      {
        title: "",
        render: (row)=><div>
        <button onClick={()=>actualiza(row)} type="button" className="btn btn-outline-warning btn-sm" data-tip="Editar">
          <li className="fa fa-edit"></li> 
        </button>
        <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm" >
          <li className="fa fa-trash"></li>
        </button>
        {
          botoneliminar ?
          <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm"  >
            Confirmar
          </button>
          : null
        }
        </div>,
      },
      {
        title: "",
        render: (row)=><div>
          {
            parseInt(row.status) === 1 ?
            <label className="label label-danger">Activo</label>
            :
            <label className="label label-danger">Inactivo</label>
          }
        </div>,
      }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="offset-md-10 col-md-2 text-right">
        <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
            <li className="fa fa-cloud-download"></li> <CSVLink data={universidades} headers={header} filename={"UniversidadesCanacintra.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
          </button> 
      </div>
      <div className="col-md-12">
        { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Lista de universidades"
      columns={columnasuniversidades}
      data={universidades}
      onRowSelected
  />
  </div>
  )
}

export default Tabla