import React, { Fragment, useState, useEffect } from 'react'
import { insertData, updateItem } from '../../helpers'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Mensaje from '../../Mensaje'

const Formulario = ({ ciudades, areas, empresas, tipovacante, getVacantes, vacante, setVacante, actualizar, setActualizar, admin }) =>{

  const { 
    area_id,
    empresa_id,
    ciudad_id,
    tipo_vacante_id,
    titulo,
    jornada,
    salario,
    desc_corta_vacante,
    desc_vacante,
    status_vacante,
    fecha_publicacion,
    palabras_clave,
    discapacitado,
    status
   } = vacante;

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  
  const actualizaState = e =>{
    setVacante({
      ...vacante,
      [e.target.name] : e.target.value
    });
  } 

  const descVancante =(desc_vacante)=>{
    console.log(desc_vacante)
    setVacante({
      ...vacante,
      desc_vacante : desc_vacante
    });
  }


  const reiniciarForm = () =>{
    //Reiniciar form
    setVacante({
      ...vacante,
      titulo: '',
      jornada: '',
      salario: '',
      desc_corta_vacante: '',
      desc_vacante: '',
      status_vacante: 1,
      fecha_publicacion: '',
      palabras_clave: '',
      discapacitado: 0,
      status: 1
    })
    //Actualizar tabla
    getVacantes();
  }
  
  const submitFormulario = async e =>{
    e.preventDefault();
    console.log('Guardando....')
    let ruta = 'vacantes';
    if(!actualizar){
      //Validar
      if (area_id ===0 || empresa_id===0 || ciudad_id ===0 || titulo==='' ){
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Faltan campos(*) obligatorios',
          etiqueta: 'error'
        })
        return;
      }
      //Guardar
      const send = await insertData(ruta,vacante);
      console.log(send);
      if(send.status === "success"){
        setMostrarMensaje({
          mostrar: true,
          etiqueta: 'success',
          mensaje: 'La vacante se ha guardado correctamente'
        });
        reiniciarForm();
        return;
      }
    }else{
      //Validar
      if (area_id ===0 || empresa_id===0 || ciudad_id ===0 || titulo==='' ){
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Faltan campos(*) obligatorios',
          etiqueta: 'error'
        })
        return;
      }
      //Actualizar
      const send = await updateItem(ruta+'/'+vacante.vacante_id,vacante);
      console.log(send);
      if(send.status === "success"){
        setMostrarMensaje({
          mostrar: true,
          etiqueta: 'success',
          mensaje: 'La vacante se ha actualizado correctamente'
        });

        reiniciarForm();
        return;
      }
    }


    setMostrarMensaje({
      mostrar: true,
      mensaje: 'No se guardó la vacante',
      etiqueta: 'error'
    })
  
  }

  return(
    <Fragment>
      <form 
        onSubmit = {submitFormulario}
      >
        <div className="row">
          <div className="col-md-12">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          </div>
          <div className="col-sm-6">
            <form method="POST">
            <div className="form-group require_area_vac">
                  <label for="formGroupExampleInput2">Tipo publicación*</label>
                  <select 
                  className="form-control"
                  name="tipo_vacante_id" 
                  value = {tipo_vacante_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Tipo de publicación --</option>
                  {
                    tipovacante ?
                    tipovacante.map(item=>(
                      <option 
                        key = {item.tipo_vacante_id}
                        value={item.tipo_vacante_id}
                        >{item.desc_tipo_vacante}
                      </option>
                    ))
                  :null
                  }
                  </select>
                </div>
                <div className="form-group require_titulo_vac">
                  <label for="formGroupExampleInput">Titulo*</label>
                  <input
                  type= "text"
                  name="titulo" 
                  value= {titulo}
                  onChange={actualizaState}
                  className="form-control" 
                  required = {!actualizar ? 'requerid' : ''}
                  />
                </div>
                <div className="form-group require_desc_vac">
                  <label for="formGroupExampleInput">Descripción Corta*</label>
                  <textarea 
                    className="form-control form-control-danger" 
                    rows="3" 
                    name="desc_corta_vacante" 
                    value= {desc_corta_vacante}
                    onChange={actualizaState}
                    ></textarea>
                </div>
                {
                  admin ? 
                    <div className="form-group require_empresa_vac">
                      <label for="formGroupExampleInput2">Empresa*</label>
                      <select 
                      className="form-control"
                      name="empresa_id" 
                      value = {empresa_id}
                      onChange = {actualizaState}
                      >
                      <option value = "0">-- Seleccione una empresa --</option>
                      {
                        empresas ?
                        empresas.map(item=>(
                          <option 
                            key = {item.empresa_id}
                            value={item.empresa_id}
                            >{item.nombre_comercial}
                          </option>
                        ))
                      :null
                      }
                      </select>
                    </div>
                  :
                  null
                }
                <div className="form-group require_area_vac">
                  <label for="formGroupExampleInput2">Área*</label>
                  <select 
                  className="form-control"
                  name="area_id" 
                  value = {area_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Seleccione un área --</option>
                  {
                    areas ?
                    areas.map(item=>(
                      <option 
                        key = {item.area_id}
                        value={item.area_id}
                        >{item.desc_area}
                      </option>
                    ))
                  :null
                  }
                  </select>
                </div>
                <div className="form-group require_jornada_vac">
                  <label for="formGroupExampleInput2">Jornada*</label>
                  <input 
                  type= "text"
                  name="jornada" 
                  value= {jornada}
                  onChange={actualizaState}
                  className="form-control" 
                  required = {!actualizar ? 'requerid' : ''}
                  />
                </div>
            </form>
          </div>
          <div className="col-sm-6">
            <form method="POST">
                <div className="form-group require_salario_vac">
                  <label for="formGroupExampleInput">Salario</label>
                  <input 
                  type= "number"
                  name="salario" 
                  value= {salario}
                  onChange={actualizaState}
                  className="form-control" 
                  required = {!actualizar ? 'requerid' : ''}
                  />
                </div>
                <div className="form-group require_ciudad_vac">
                  <label for="formGroupExampleInput">Ciudad*</label>
                  <select 
                  className="form-control"
                  name="ciudad_id" 
                  value = {ciudad_id}
                  onChange = {actualizaState}
                  >
                  <option value = "0">-- Seleccione un municipio --</option>
                  {
                    ciudades ?
                    ciudades.map(item=>(
                      <option 
                        key = {item.ciudad_id}
                        value={item.ciudad_id}
                        >{item.desc_ciudad}
                      </option>
                    ))
                  :null
                  }
                  </select>
                </div>
                <div className="form-group require_dis_vac">
                  <label for="formGroupExampleInput">Apto Discapacitado</label>
                  <select
                  className="form-control"
                  name="discapacitado" 
                  value = {discapacitado}
                  onChange = {actualizaState}
                  >
                    <option value="0">No</option>
                    <option value="1">Si</option>
                  </select>
                </div>
                <div className="form-group require_palabras_vac">
                  <label for="formGroupExampleInput">Palabras Clave*</label>
                  <textarea 
                    className="form-control form-control-danger" 
                    rows="2" 
                    name="palabras_clave"
                    valeu = {palabras_clave}
                    onChange = {actualizaState}
                    ></textarea>
                </div>
                <div className="form-group">
                  <select
                    className = "form-control"
                    name="status_vacante"
                    value = {status_vacante ? status_vacante : 0}
                    onChange = {actualizaState}
                  >
                    <option value="0">Inactivo</option>
                    <option value="1">Activo</option>
                  </select>
              </div> 
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" >
            <div className="form-group">
              <label for="desc_vac2">Descripción:</label>
              <div className="require_desc_vac2">
              <CKEditor
                    editor={ ClassicEditor }
                    data={desc_vacante === "" || !desc_vacante ? 'Escribe aquí la descripción' : desc_vacante}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        descVancante(data);
                    } }
                />
              </div>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-12">
            {
            actualizar ?
            <button className="mt-1 btn btn-warning">Actualizar publicación</button>
            :
            <button className="mt-1 btn btn-primary">Publicar</button>
            }
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default Formulario