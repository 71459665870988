import React, { Fragment, useState, useEffect } from 'react'
import { getData } from '../helpers'
import Mensaje from '../Mensaje'

import Menu from '../Template/Menu'
import Item from './ItemProyecto'

const Vacantes = () =>{
  const [proyectos, setProyectos] = useState([]);
  const [usuario, setUsuario] = useState({});
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getProyectos =  async () =>{
    const send = await getData('proyectos');
    if(send.status==="success"){
      setProyectos(send.datos);
    }
  }


  useEffect( async ()=>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    setUsuario(user);
    if(!user){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe ser un usuario registrado',
        etiqueta: 'error'
      })
      return;
    }
    getProyectos();
  },[]);

  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={4}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="wow animated fadeIn" data-wow-delay="0.15s"></div>
      <div id="fh5co-features" className="fh5co-features-outer">
        <div className="container">
          <h1 className="second-title" style={{ marginTop: 250 }}><span className="span-features">PROPUESTAS</span></h1>
          <p>Contacta con los emprendedores y proyectos que te interesan.</p>
          {
            usuario ?
              proyectos.length > 0 ?
              proyectos.map(item=><Item key={item.proyecto_id} item = {item} />)
              : <p>No se encontraron proyectos</p>
            :
            <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} />
          }
          <div style={{ marginTop: 100, marginBottom: 50 }}>
            <img width= "100%" src="img/banners/jovenes.jpeg" />
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default Vacantes