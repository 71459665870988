import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Container from './Container'
import ContainerEmpresa from './Administrador/Empresa/ContainerEmpresa'
import ContainerUniversidad from './Administrador/Universidad/ContainerUniversidad'
import ContainerUsuario from './Administrador/Usuario/ContainerUsuario'
import ContainerRegistrarFecha from './Administrador/RegistrarFecha/ContainerRegistrarFecha'
import ContainerVacante from './Administrador/Vacante/ContainerVacante'
import ContainerConsulta from './Administrador/Consulta/ContainerConsulta'
import ContainerDatosEmpresa from './Administrador/DatosEmpresa/ContainerDatosEmpresa'
import ContainerConvocatoria from './Administrador/Convocatorias/ContainerConvocatoria';
import ContainerPromociones from './Administrador/Promociones/ContainerPromociones'

import ContainerDatosUniverdidad from './Administrador/DatosUniversidad/ContainerDatosUniversidad'
import ContainerValidarAlumno from './Administrador/ValidarAlumno/ContainerValidarAlumno'

const Main = () => {
  return (
    <Router>
      <main>
          <Switch>
            <Route path="/admin-consultas"  component={ContainerConsulta} />
            <Route path="/admin-usuarios"  component={ContainerUsuario} />
            <Route path="/admin-universidades"  component={ContainerUniversidad} />
            <Route path="/admin-datos-empresa"  component={ContainerDatosEmpresa} />
            <Route path="/admin-datos-universidad"  component={ContainerDatosUniverdidad} />
            <Route path="/admin-validar-alumnos"  component={ContainerValidarAlumno} />
            <Route path="/admin-registrar-fechas"  component={ContainerRegistrarFecha} />
            <Route path="/admin-empresas"  component={ContainerEmpresa} />
            <Route path="/admin-vacantes"  component={ContainerVacante} />
            <Route path="/admin-convocatorias"  component={ContainerConvocatoria} />
            <Route path="/admin-promociones"  component={ContainerPromociones} />
            <Route path="/"  component={Container} />
          </Switch>
      </main>
    </Router>

  );
}

export default Main;
