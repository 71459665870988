import React from 'react'


const Footer = () =>{
  return(
    <footer className="footer-outer">
      <div className="container footer-inner">

        <div className="footer-three-grid wow fadeIn animated" data-wow-delay="0.66s">
          <div className="column-1-3">
            <img src="img/logo_canacintra.png" width="200" />
          </div>
          <div className="column-2-3">
            <nav className="footer-nav">
              <ul>
                <a href="/" ><li>Inicio</li></a>
                <a href="vacantes-practicas" ><li className="">Prácticas/Servicio/Vacantes</li></a>
                {/* <a href="vacantes-servicio" ><li className="">Servicio Social</li></a> */}
                <a href="proyectos" ><li className="">Proyectos</li></a>
                <a href="universidades" ><li>Universidades</li></a>
                <a href="empresas" ><li>Empresas</li></a>
                <a href="#" ><li className="">Contacto</li></a>
              </ul>
            </nav>
          </div>
          <div className="column-3-3">
            <div className="social-icons-footer">
              <a href="https://www.facebook.com/CanacintraDelegacionTabasco/" target="new"><i className="fab fa-facebook-f"></i></a>
              {/* <a href="#"><i className="fab fa-instagram"></i></a> */}
              <a href="https://twitter.com/CANACINTRA_Tab" target="new" ><i className="fab fa-twitter"></i></a>
            </div>
          </div>
        </div>

        <span className="border-bottom-footer"></span>
        <p style={{ textAlign: 'center' }}><a href="/aviso-privacidad" target="_blank">Aviso de privacidad</a>.</p>
        <p className="copyright">&copy; 2020 Todos los derechos reservados. <a href="http://www.canacintratabasco.org.mx/" target="_blank">Canacintra Tabasco</a>.</p>

      </div>
    </footer>
  );
}

export default Footer