import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { triggerBase64Download } from 'react-base64-downloader';
import { insertData } from './helpers'

const Modal = ( { vacante } ) => {
  const [postulados, setPostulados] = useState([]);
  const [pdf, setPdf] = useState(null)

  const [columnasvacantes, setColumnasVacantes] = useState(
    [
      {
        title: "Nombre",
        render: (row)=><div>{row.nombre+' '+row.apellidos}</div>
      },
      {
        title: "Curriculum",
        render: (row)=><div>
                          <button onClick={()=>setPdf(row.cv)} type="button" className="btn btn-default"><li style={{ color: 'green', fontSize: 25 }} className="fa fa-address-card"></li></button>
                          <a download='documento' href={row.cv} title='Descargar' ><li style={{ fontSize: 25}} className="fa fa-arrow-circle-down"></li></a>
                        </div>
      },
    ])

    const getPostulados =  async () =>{
      let vacante_id = {
        vacante_id: vacante.vacante_id
      }
      const send = await insertData('postulante-vacante',vacante_id);
      console.log(send)
      if(send.status==="success"){
        setPostulados(send.datos);
      }
    }

    useEffect(()=>{
      getPostulados();
    },[vacante])

  return (
    <div id="modalPostulados" className="modal fade" role="dialog">
      <form>
        <div class="modal-dialog modal-lg" style={{ width: '80%'}}>
          <div className="modal-content">
            <div className="modal-header">
              <h6>{vacante.nombre}</h6>
              <h6>Postulados</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-5">
                  <MaterialTable
                    options={{
                      headerStyle:{fontSize:'12px'},
                      bodyStyle:{fontSize:'12px'},
                      pageSize: 10
                    }}
                    title=""
                    columns={columnasvacantes}
                    data={postulados}
                    onRowSelected
                  />
                </div>
                <div className="col-md-7" style={{ backgroundColor: 'gray' }}>
                  <iframe src={pdf} frameBorder="0"  width="100%" height="600px" />
                  {/* <embed src={pdf} width="100%" height="600" type="application/pdf"/> */}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Modal;
