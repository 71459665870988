import React from 'react'

const Item = ( { item, eliminaItem } ) =>{
  return(
    <div className="row">
      <div style={{  marginTop: 20, marginBottom: 10}} className="col-sm-12 in-order-2 wow animated fadeInRight" data-wow-delay="0.22s">
        {
          item.status_vacante === 1 ?
          <h6><span class="badge badge-success">Activo</span></h6>
          :
          <h6><span class="badge badge-danger">Ocupado</span></h6>
        }
        <h3 className="titulo-vacante"><a href={'vacante?id='+item.vacante_id}>{item.titulo}</a></h3>
        <h5 className="empresa-vacante">{item.nombre_comercial}</h5>
        <p>{item.desc_corta_vacante}</p>
        <span className="circle circle-first fecha-vacante">Fecha: {item.fecha_publicacion}</span>
        <br/>
        <button type="button" onClick={()=>eliminaItem(item.postulantes_id)} className="mt-1 btn btn-danger">Remover</button>
        <hr/>
      </div>
    </div> 
  )
}

export default Item