import React, { Fragment, useState } from 'react'
import { insertData, updateItem } from '../../helpers'
import FileBase64 from 'react-file-base64';
import Mensaje from '../../Mensaje'

const Formulario = ({ fecha, setFecha, getFechas }) =>{
  const { universidad_id, tipo, fecha_inicio, fecha_fin, status } = fecha;
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  
  const actualizaState = e =>{
    setFecha({
      ...fecha,
      [e.target.name] : e.target.value
    });
  } 
  
  const submitFormulario = async e =>{
    e.preventDefault();

    //Validar
    if (tipo ===0 || fecha_inicio ==='' || fecha_fin==='' ){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Faltan campos(*) obligatorios',
        etiqueta: 'error'
      })
      return;
    }
    if (Date.parse(fecha_inicio) > Date.parse(fecha_fin) ){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'La fecha de inicio debe ser menor a la fecha final',
        etiqueta: 'error'
      })
      return;
    }

    //Guardar
    let ruta = 'registrar-fecha';
    const send = await insertData(ruta,fecha);
    console.log(send);
    if(send.status === "success"){
      setMostrarMensaje({
        mostrar: true,
        etiqueta: 'success',
        mensaje: 'Se ha registrado la fecha correctamente'
      });
      getFechas();
      return;
    }

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'No se guardó la fecha',
      etiqueta: 'error'
    })
  
  }

  return(
    <Fragment>
      <form 
        onSubmit = {submitFormulario}
      >
        <div className="row">
          <div className="col-md-12">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          </div>
          <div className="col-md-3">
            <div className="form-group require_giro_emp">
              <label for="formGroupExampleInput2">Tipo</label>
              <select 
                className="form-control"
                name="tipo" 
                value = {tipo}
                onChange = {actualizaState}
                >
                <option value = "practicas">Prácticas</option>
                <option value = "servicio">Servicio social</option>
                <option value = "estadias">Estadías</option>
                <option value = "estancia">Estancías</option>
                </select>
              </div>
          </div>
          <div className="col-sm-3">
                <div className="form-group require_nom_comer_emp">
                  <label for="formGroupExampleInput">Fecha inicio*</label>
                  <input 
                    type= "date"
                    name="fecha_inicio" 
                    value= {fecha_inicio}
                    onChange={actualizaState}
                    className="form-control" 
                    required
                  />
                </div>
          </div>
          <div className="col-sm-3">
              <div className="form-group require_razon_emp">
                <label for="formGroupExampleInput">Fecha fin*</label>
                <input 
                    type= "date"
                    name="fecha_fin" 
                    value= {fecha_fin}
                    onChange={actualizaState}
                    className="form-control" 
                    required
                  />
              </div>
          </div>
          <div className="col-md-3">
            <button className="mt-4 btn btn-warning">Guardar fecha</button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default Formulario