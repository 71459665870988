import React, { useState } from 'react'
import MaterialTable from 'material-table';
import Mensaje from '../../Mensaje'
import ModalPostulados from '../../ModalPostulados'
import { CSVLink } from "react-csv";

const Tabla = ({ vacantes }) =>{
  const [vacante, setVacante] = useState({
    vacante_id: 0,
    nombre: ''
  });
  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const header = [
    { label: "Empresa", key: "nombre_comercial" },
    { label: "Vacante", key: "titulo" },
    { label: "Área", key: "desc_area" },
    { label: "Postulados", key: "num" },
    { label: "Fecha", key: "fecha_publicacion" }
  ];

  const [columnasvacantes, setColumnasVacantes] = useState(
    [
      { title: "Empresa", field: "nombre_comercial" },
      { title: "Vacante", field: "titulo" },
      { title: "Área", field: "desc_area" },
      {
        title: "Postulados",
        render: (row)=><div>
          {
            row.num > 0 ?
            <a href = "#" onClick={()=>setVacante({
              vacante_id: row.vacante_id,
              nombre: row.titulo
            })} data-toggle="modal" data-target="#modalPostulados"><h5><span class="badge badge-success">{row.num}</span></h5></a>
            :
            <label className="label label-danger">0</label>
          }
        </div>,
      },
      { title: "Fecha", field: "fecha_publicacion" },
      {
        title: "",
        render: (row)=><div>
          {
            row.status_vacante === 1 ?
            <label className="label label-danger">Activo</label>
            :
            <label className="label label-danger">Inactivo</label>
          }
        </div>,
      }
    ]
  );

  return(
    <div className="col-md-12">
      <ModalPostulados 
        vacante = {vacante}
      />
      <div className="offset-md-10 col-md-2 text-right">
        <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
            <li className="fa fa-cloud-download"></li> <CSVLink data={vacantes} headers={header} filename={"VacantesEmpresaCanacintra.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
          </button> 
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Lista de vacantes por empresa"
      columns={columnasvacantes}
      data={vacantes}
      onRowSelected
  />
  </div>
  )
}

export default Tabla