import React, { Fragment, useState, useEffect } from 'react'
import { getData, deleteItem } from '../../helpers'

import MenuAdministrador from '../../Template/MenuAdministrador'
import Formulario from './Formulario'
import Tabla from './Tabla'

const ContainerUniversidad = () =>{
  const [ciudades, setCiudades] = useState([]);
  const [universidades, setUniversidades] = useState([]); 
  const [universidad, setUniversidad]= useState({
    email: '',
    password: '',
    ciudad_id: 0,
    nombre_comercial: '',
    razon_social: '',
    rfc: '',
    direccion: '',
    logo: '',
    telefono: '',
    nombre_contacto: '',
    telefono_contacto: '',
    status: 1
  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getCiudades =  async () =>{
    const send = await getData('ciudades');
    console.log(send)
    if(send.status==="success"){
      setCiudades(send.datos);
    }
  }


  const getUniversidades =  async () =>{
    const send = await getData('universidades');
    console.log(send)
    if(send.status==="success"){
      setUniversidades(send.datos);
    }
  }

  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setUniversidad(data);
    //setImagen(dominio+'/'+data.img)
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('universidades/'+data.universidad_id);
    console.log(send);
    if(send.code==="error"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'No se eliminó la universidad',
        etiqueta: 'error'
      })
    }
    if(send.status==="success"){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la universidad',
        etiqueta: 'danger'
      })
      getUniversidades();
    }
  }


  useEffect(()=>{
    getCiudades();
    getUniversidades();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={3} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Alta Universidades</h2>
              <hr/>
            </div>
          </div>
          <Formulario 
            ciudades = {ciudades} 
            getUniversidades = {getUniversidades}

            setActualizar = {setActualizar}
            actualizar = {actualizar}

            setUniversidad = {setUniversidad}
            universidad = {universidad}
          />
          <Tabla 
            universidades = {universidades}

            actualiza = {actualiza}
            eliminaItem = {eliminaItem}

            mostrarmensaje2 = {mostrarmensaje2}


          />
        </div>
    </Fragment>
  )
}

export default ContainerUniversidad