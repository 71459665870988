import React, {useState} from 'react';
import { insertData } from './helpers'
import Mensaje from './Mensaje'

const Modal = () => {
  const [user, actualizaUser] = useState({
    email:'',
    password: '',
    password_confirmation:'',
    tipo: ''
  })

  //Extraer valores
  const {email, password, password_confirmation, tipo} = user;

  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });
  const {message, label} = mensaje;


  const actualizaState = e =>{
    actualizaUser({
      ...user,
      [e.target.name] : e.target.value
    })
  }


  const submitFormulario = async e =>{
    e.preventDefault();
    console.log('Registrando....');

    if(password !== password_confirmation){
      actualizaMensaje({
        status:true,
        label: "error",
        message: "Las contraseñas no coinciden."
      });
      return;
    }

    if(tipo === ''){
      actualizaMensaje({
        status:true,
        label: "error",
        message: "Seleccione un tipo de usuario"
      });
      return;
    }

    const sendUser = await insertData('register',user);
    console.log(sendUser);
    if(sendUser.usuario){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha registrado correctamente."
      });
      actualizaUser({
        email:'',
        password: '',
        password_confirmation:'',
        tipo: ''
      })
      return;
    }
    if(sendUser.errors){
      if(sendUser.errors.email[0] ==="The email has already been taken."){
        actualizaMensaje({
          status:true,
          label: "error",
          message: "Ya se ha registrado un usuario con este correo."
        });
        return;
      }
    }
    actualizaMensaje({
      status:true,
      label: "error",
      message: 'Ha ocurrido un error.'
    })
  }

  return (
    <div id="modalRegistro" className="modal fade" role="dialog">
      <form
        onSubmit={submitFormulario}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Regístrate</h5>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              {mensaje.status ? <Mensaje etiqueta={label} mensaje={message} />: null}
              <div className="form-group">
                <p>Correo</p>
                <input 
                type= "email"
                name="email" 
                value= {email}
                onChange={actualizaState}
                className="form-control" 
                required
                />
              </div>
              <div className="form-group">
                <p>Contraseña</p>
                <input 
                type= "password"
                name="password" 
                value= {password}
                onChange={actualizaState}
                className="form-control" 
                required
                />
              </div>
              <div className="form-group">
                <p>Confirmar contraseña</p>
                <input 
                type= "password"
                name="password_confirmation" 
                value= {password_confirmation}
                onChange={actualizaState}
                className="form-control" 
                required
                />
              </div>
              <div className="form-group">
                <p>Tipo</p>
                <select className="form-control"
                  onChange={actualizaState}
                  name="tipo" 
                  value= {tipo}
                >
                  <option>-- Seleccione un tipo --</option>
                  <option value= "Alumno">Alumno/Egresado</option>
                  <option value= "Alumno">Maestrante/Doctorante</option>
                  <option value= "Universidad">Universidad</option>
                  <option value= "Empresa">Empresa</option>
                </select>
              </div>
              <label>* Después de registrarse debe completar sus datos.</label>
            </div>
            <button className="mt-1 btn btn-primary">Regístrarme</button>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
      
        </div>
      </form>
    </div>
  );
}

export default Modal;
