import React, { Fragment, useState, useEffect } from 'react'
import { insertData, getData } from '../helpers'
import Mensaje from '../Mensaje'
import { useLocation } from 'react-router-dom'

import ModalPostularse from '../ModalPostularse'

import Menu from '../Template/Menu'
import Item from './ItemVacante'

const Vacantes = () =>{
  const [usuario, setUsuario] = useState(null);
  const [tipo, setTipo] = useState('');
  const [fechas, setFechas] = useState([]);
  const [postulado, setPostulado] = useState(false);
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const [vacante, setVacante] = useState([]);

  const getVacante =  async () =>{
    let id = query.get("id");
    if(!id || id ==='null'){
      window.location = '/vacantes-practicas';
    }
    const send = await getData('vacantes/'+id);
    console.log(send)
    if(send.status==="success"){
      setVacante(send.datos[0]);
    }
  }

  const validaPostulante = async() =>{
    let id_vacante = query.get("id");
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(user){
      let postulante = {
        usuario_id: user.usuario_id,
        vacante_id: id_vacante,
        status: 0
      }

      //Validar fecha programada
      let ruta = 'validar-fecha';
      const send1 = await insertData(ruta,user);
      console.log(send1)
      if(send1.postular==="no"){
        setMostrarMensaje2({
          mostrar: true,
          mensaje: send1.message,
          etiqueta: 'error'
        })
      }

      ruta = 'postulante';
      const send = await insertData(ruta,postulante);
      console.log(send);
      if(send.status==="success"){
        setPostulado(true);
        return;
      }
    }
  }

  const postularVancante = async() => {
    console.log('Postulando....');
    let id_vacante = query.get("id");
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    if(!user.usuario_id){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'No puedes postularte a esta vacante, debe ser un usuario.',
        etiqueta: 'error'
      })
      return;
    }
    let ruta = 'postulantes';
    let postulante = {
      usuario_id: user.usuario_id,
      vacante_id: id_vacante,
      status: 0
    }
    const send = await insertData(ruta,postulante);
    if(send.status === "success"){
      setPostulado(true);
      return;
    }
  }

  useEffect( async ()=>{
      const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
      if(user){
          setTipo(user.tipo);
          setUsuario(user);
      }
      getVacante();
      validaPostulante();
    },[]);  

  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={4}  />
      </div> 
      <ModalPostularse 
        postularVancante = {postularVancante}
        postulado = {postulado}
      />
      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="curved-bg-div wow animated fadeIn" data-wow-delay="0.15s"></div>
      <div id="fh5co-features" className="fh5co-features-outer">
        <div className="container">
          <h1 className="second-title" style={{ marginTop: 200 }}><span className="span-features">{vacante.titulo}</span></h1>
          {
          vacante.tipo_vacante_id === 1 ?
            <p style={{ marginBottom: 0, color: 'red' }}>Prácticas</p> :
            vacante.tipo_vacante_id === 2 ?
            <p style={{ marginBottom: 0, color: 'red' }}>Servicio social</p> :
            vacante.tipo_vacante_id === 3 ? 
            <p style={{ marginBottom: 0, color: 'red' }}>Vacante</p> : null
          }
          <h4 className="second-title" ><small className="span-features">{vacante.fecha_publicacion}</small></h4>
          <hr/>
        <div className="row">
        <div className="col-md-12 text-center">
          {
            !usuario ? 
              <button className="col-md-12 btn btn-md features-btn-first animated fadeInLeft wow btn-primary" data-wow-delay="0.95s" data-toggle="modal" data-target="#modalSesion">Debe iniciar sesión para ver el detalle</button>
            : 
              null
          }
          </div>
          <div className="col-md-8">
            <div  dangerouslySetInnerHTML={{__html: vacante.desc_vacante}}>
            </div>
            <div style={{ marginBottom: 200 }} className="container fh5co-hero-inner">
              <button onClick={()=>{
                let path = query.get("p");
                if (path !==''){
                  window.location = '/'+path;
                }else{
                  window.location = '/vacantes-practicas';
                }
                }} className=" col-md-3 btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s"><li className="fa fa-chevron-left"></li> Regresar</button>
            </div>
          </div>
          <div className="col-md-4">
            <h3>Resumen</h3>
            <p style={{ marginBottom: 0, marginTop: 20 }}><b>Empresa:</b></p>
            <p><a href="#">{vacante.nombre_comercial}</a></p>
            <p style={{ marginBottom: 0, marginTop: 20 }}><b>Lugar:</b></p>
            <p><a href="#">{vacante.desc_estado}, {vacante.desc_ciudad}.</a></p>
            {/* <p style={{ marginBottom: 0, marginTop: 20 }}><b>Salario:</b></p>
            <p>${vacante.salario}</p> */}
            <p style={{ marginBottom: 0, marginTop: 20 }}><b>Jornada:</b></p>
            <p>{vacante.jornada}</p>
            <div className="container fh5co-hero-inner">
              {
                !postulado ?
                  mostrarmensaje2.mostrar ?
                    <button style={{ backgroundColor: 'gray' }} className="col-md-12 btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" >No disponible</button>
                  :
                    <button style={{ backgroundColor: 'red' }} className=" col-md-12 btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" data-toggle="modal" data-target="#modalPostularse">Postularme</button>
                :
                  <button style={{ backgroundColor: 'gray' }} className="col-md-12 btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" >¡Ya está postulado!</button>
              }
              { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
              { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
            </div>
          </div>
        </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Vacantes