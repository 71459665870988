import React, { Fragment, useState, useEffect } from 'react'
import { getData, insertData } from '../helpers'
import { useLocation } from 'react-router-dom'

import Menu from '../Template/Menu'
import Item from '../VacantesPracticas_/ItemVacante'
import Mensaje from '../Mensaje'
import { findRenderedDOMComponentWithClass } from 'react-dom/cjs/react-dom-test-utils.development'

const Universidades = () =>{
  const [vacantes, setVacantes] = useState([]);
  const [empresa, setEmpresa]= useState({});
  const [promociones, setPromociones] = useState([]);
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: true,
    mensaje: 'Cargando...',
    etique: ''
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();


  const getEmpresa =  async () =>{
    let id = query.get("emp");
    const send = await getData('empresas/'+id);
    console.log(send)
    if(send.status==="success"){
      setEmpresa(send.datos);
    }
  }

  const getPromociones =  async () =>{
    let id = query.get("emp");
    let datos = {
      empresa_id: id
    }
    const send = await insertData('promociones-empresa-empresa',datos);
    if(send.status==="success"){
      setPromociones(send.datos);
    }
  }


  const getVacantesEmpresa =  async () =>{
    let id = query.get("emp");
    if(!id || id ==='emp'){
      window.location = '/empresas';
    }
    let empresa = {
      value: id
    }
    let ruta = 'filtrar-vacantes-empresa';
    const send = await insertData(ruta,empresa);
    if(send.status==="success"){
      setVacantes(send.datos);
    }
  }
  useEffect(()=>{
    getVacantesEmpresa();
    getEmpresa();
    getPromociones();
  },[]);

  
  return(
    <Fragment>
      <div id="fh5co-hero-wrapper2">
        <Menu activar={6}  />
      </div> 

      {/* <!-- ==========================================================================================================
                              ADVANTAGES
        ========================================================================================================== --> */}
      <div className="curved-bg-div wow animated fadeIn" data-wow-delay="0.15s" style={{ height: 50, marginBottom: 100 }}></div>
      <div id="fh5co-features" className="">
        <div className="container">
          <h1>DATOS DE LA EMPRESA:</h1>
          <div className="row">
            <div className="col-md-3">
              <div className="text-center" style={{ height: 200, borderStyle: 'solid', borderColor: '#d6d6d6', borderWidth: 1, alignContent: 'center', justifyContent: 'center', display: 'flex', alignSelf: 'center', alignItems: 'center'}}>
                <img src={empresa.logo} width="200" style= {{ display:'block', margin: 'auto', alignSelf: 'center' }} />
              </div>
            </div>
            <div className="col-md-4">
              <p style={{ marginBottom: 0, marginTop: 20 }}><b>Empresa:</b></p>
              <p><a href="#">{empresa.nombre_comercial}</a></p>
              <p style={{ marginBottom: 0, marginTop: 20 }}><b>Teléfono:</b></p>
              <p><a href="#">{empresa.telefono}</a></p>
            </div>
          </div>
          <h1 className="second-title" style={{ marginTop: 50 }}><span className="span-features">PRÁCTICAS/SERVICIO/VACANTES</span></h1>
          {
            vacantes.length > 0 ?
            <Fragment>
              {
                vacantes.length > 0 ?
                vacantes.map(item=><Item key={item.vacante_id} item = {item} path = {'empresa?emp='+query.get("emp")} />)
                : <p>No se encontraron vacantes</p>
              }
              </Fragment>
            :
            <p>Esta empresa aún no tiene vacantes.</p>
          }
        </div>
        <div className="container text-center">
          <div className="col-md-12" style={{ marginTop: 100, marginBottom: 50 }}>
            {
              promociones.length > 0 ?
                <h3><b>-</b></h3>
              : null
            }
          </div>
          {
            promociones.length > 0 ?
              promociones.map(item=>(
                <div className='text-center' style={{ marginTop: 50 }}>
                  <hr/>
                  <p>{item.desc_promocion_empresa}</p>
                  <img width="80%" src={item.imagen}  />
                </div>
              ))
            : null
          }
        </div>
      </div>



    </Fragment>
  );
}

export default Universidades