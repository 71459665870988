import React, { Fragment, useState, useEffect } from 'react'
import { getData, deleteItem } from '../../helpers'

import MenuAdministrador from '../../Template/MenuAdministrador'
import Formulario from './Formulario'

const ContainerEmpresa = () =>{
  const [ciudades, setCiudades] = useState([]);
  const [giros, setGiros] = useState([]);
  const [empresa, setEmpresa]= useState({
    email: '',
    password: '',
    ciudad_id: 0,
    giro_id: 0,
    nombre_comercial: '',
    razon_social: '',
    rfc: '',
    direccion: '',
    logo: '',
    telefono: '',
    nombre_contacto: '',
    telefono_contacto: '',
    status: 1
  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getCiudades =  async () =>{
    const send = await getData('ciudades');
    console.log(send)
    if(send.status==="success"){
      setCiudades(send.datos);
    }
  }

  const getGiros =  async () =>{
    const send = await getData('giros');
    console.log(send)
    if(send.status==="success"){
      setGiros(send.datos);
    }
  }

  const getEmpresa =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CANA_USER'));
    const send = await getData('empresas/'+user.empresa_id);
    console.log(send)
    if(send.status==="success"){
      setEmpresa(send.datos);
    }
  }


  useEffect(()=>{
    getCiudades();
    getGiros();
    getEmpresa();
  },[]);

  return(
    <Fragment>
      <MenuAdministrador activar={6} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <br/>
              <h2>Completar datos de la empresa</h2>
              <hr/>
            </div>
          </div>
          <Formulario 
            ciudades = {ciudades} 
            giros = {giros}
            setEmpresa = {setEmpresa}
            empresa = {empresa}
          />
        </div>
    </Fragment>
  )
}

export default ContainerEmpresa