import React, { useState } from 'react'
import MaterialTable from 'material-table';
import Mensaje from '../../Mensaje'
import { CSVLink } from "react-csv";

const Tabla = ({ usuarios, actualiza, mostrarmensaje2, eliminaItem, botoneliminar, setBotonEliminar }) =>{

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const header = [
    { label: "Nombre", key: "nombre" },
    { label: "Apellidos", key: "apellidos" },
    { label: "Correo", key: "email" },
    { label: "Matricula", key: "matricula" },
    { label: "Carrera", key: "carrera" },
    { label: "Universidad", key: "nombre_comercial" },
    { label: "Área", key: "desc_area" },
    { label: "Ciudad", key: "desc_ciudad" }
  ];

  const [columnasuniversidades, setColumnasUniversidades] = useState(
    [
      { title: "Nombre", field: "nombre" },
      { title: "Apellidos", field: "apellidos" },
      { title: "Correo", field: "email" },
      { title: "Matricula", field: "matricula" },
      { title: "Carrera", field: "carrera" },
      { title: "Universidad", field: "nombre_comercial" },
      { title: "Área", field: "desc_area" },
      { title: "Ciudad", field: "desc_ciudad" },
      // {
      //   title: "",
      //   render: (row)=><div>
      //   <button onClick={()=>actualiza(row)} type="button" className="btn btn-outline-warning btn-sm" data-tip="Editar">
      //     <li className="fa fa-edit"></li> 
      //   </button>
      //   <button onClick={()=>setBotonEliminar(true)} type="button" className="btn btn-outline-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#mediumModalEliminaCotizacion">
      //     <li className="fa fa-trash"></li>
      //   </button>
      //   {
      //     botoneliminar ?
      //     <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm"  >
      //       Confirmar
      //     </button>
      //     : null
      //   }
      //   </div>,
      // },
      {
        title: "",
        render: (row)=><div>
          {
            parseInt(row.status) === 1 ?
            <label className="label label-danger">Incompleto</label>
            :
            <label className="label label-success" style={{ color: 'green'}}>Completo</label>
          }
        </div>,
      }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="offset-md-10 col-md-2 text-right">
        <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
            <li className="fa fa-cloud-download"></li> <CSVLink data={usuarios} headers={header} filename={"UsuariosCanacintra.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
          </button> 
      </div>
      <div className="col-md-12">
        { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Lista de usuarios"
      columns={columnasuniversidades}
      data={usuarios}
      onRowSelected
  />
  </div>
  )
}

export default Tabla