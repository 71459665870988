import React, {useState} from 'react';
import { insertData } from './helpers'
import Mensaje from './Mensaje'

const Modal = () => {
  const [user, actualizaUser] = useState({
    password_confirmation:'',
    password: ''
  })

  //Extraer valores
  const {password_confirmation, password} = user;

  const [mensaje, setMostrarMensaje] = useState({
    status:false,
    message:'',
    label:''
  });
  const {message, label} = mensaje;


  const actualizaState = e =>{
    actualizaUser({
      ...user,
      [e.target.name] : e.target.value
    })
  }


  const submitFormulario = async e =>{
    e.preventDefault();

    //Validar
    if (password ===''){
      setMostrarMensaje({
        status: true,
        message: 'Faltan campos(*) obligatorios',
        label: 'error'
      })
      return;
    }

    if (password !== password_confirmation){
      setMostrarMensaje({
        status: true,
        message: 'Las contraseñas no coinciden',
        label: 'error'
      })
      return;
    }

    let ruta = 'usuario-usuario';
    const usr =  await JSON.parse(localStorage.getItem('CANA_USER'));
    let usuario = {
      usuario_id : usr.id,
      password: user.password
    }
    const send = await insertData(ruta,usuario);
    console.log(send);
    if(send.status === "success"){
      setMostrarMensaje({
        status: true,
        message: 'Se ha actualizado correctamente',
        label: 'success'
      });
      actualizaUser({
        password_confirmation:'',
        password: ''
      })
      return;
    }

    setMostrarMensaje({
      status: true,
      message: 'Ha ocurrido algo',
      label: 'error'
    });
  }



  
  return (
    <div id="modalActualizaUsuario" className="modal fade" role="dialog">
      <form
        onSubmit={submitFormulario}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Actualizar contraseña</h5>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              {mensaje.status ? <Mensaje etiqueta={label} mensaje={message} />: null}
              <div className="form-group">
                <p>Contraseña</p>
                <input id="password" type="password" className="form-control col-md-12" name="password" value={password} onChange={actualizaState}  required/>
              </div>
              <div className="form-group">
                <p>Confirmar Contraseña</p>
                <input id="password_confirmation" type="password" className="form-control col-md-12" name="password_confirmation" value={password_confirmation} onChange={actualizaState}  required  />
              </div>
            </div>
            <button className="mt-1 btn btn-warning">Actualizar contraseña</button>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Modal;
