import React from 'react'

const Item = ( { item, path } ) =>{
  return(
    <div className="row">
      <div style={{  marginTop: 20, marginBottom: 10}} className="col-sm-12 in-order-2 wow animated fadeInRight" data-wow-delay="0.22s">
        <h3 className="titulo-vacante"><a href={'vacante?id='+item.vacante_id+'&p='+path}>{item.titulo}</a></h3>
        <h5 className="empresa-vacante">{item.nombre_comercial}</h5>
        {
          item.tipo_vacante_id === 1 ?
            <p style={{ marginBottom: 0, color: 'red' }}>Prácticas</p> :
            item.tipo_vacante_id === 2 ?
            <p style={{ marginBottom: 0, color: 'red' }}>Servicio social</p> :
            item.tipo_vacante_id === 3 ? 
            <p style={{ marginBottom: 0, color: 'red' }}>Vacante</p> : null
        }
        <p>{item.desc_corta_vacante}</p>
        <span className="circle circle-first fecha-vacante">Fecha: {item.fecha_publicacion}</span>
        <hr/>
      </div>
    </div> 
  )
}

export default Item